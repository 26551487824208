import ApiService from 'api/apiService';
import { shortSymbol } from '../common/constants';
import { getNewYorkFormattedDate, getThreeDates, humanizePrice } from '../components/utils';
import React, { useState, useEffect } from 'react';
import { DateNYTime } from '../components/Date/DateNYTime';
import LinkToKitco from '../components/Link/LinkToKitco';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import clsx from 'clsx';
import { useGoogleAnaliticsHandler } from '../hooks/useGoogleAnaliticsHandler';

interface LondonFixProps {
  config: WidgetConfig; 
}

const LondonFixWidget = ({config}: LondonFixProps) => {
  const [prices, setPrices] = useState<any>(null);

  const [dates, setDates] = useState({
    '1': '',
    '2': '',
    '3': ''
  });

  useGoogleAnaliticsHandler('London_Fix_Widget');
  const {widgetWrapperRef} = useSwitchThemeModeInWidget(config.colorTheme); 
  const isSm = config.width <= 450; 


  const getDataForDays = async (
    daysToFetch: number = 3
  ): Promise<any[]> => {
    const results: any[] = [];
    let currentDate = new Date();
    
    while (results.length < daysToFetch) {
      // const formattedDate = currentDate.toISOString().split('T')[0]; 
      const formattedDate = getNewYorkFormattedDate(currentDate); 
      console.log('dateNY', formattedDate)
      try {
        const result = await ApiService.getLFValue(formattedDate);
        results.push({ date: formattedDate, data: result });
      } catch (error) {
        console.error(`Error fetching data for ${formattedDate}:`, error);
      }

      currentDate.setDate(currentDate.getDate() - 1);
  
      if (currentDate < new Date('2023-01-01')) {
        console.warn('Exceeded date limit, breaking the loop.');
        break;
      }
    }
  
    return results;
  };  

  const getDataHandler = async () => {
    try {
      const result = await getDataForDays(); 
      setDates({
        '1': formatDate(result[0].date),
        '2': formatDate(result[1].date),
        '3': formatDate(result[2].date)
      });
      console.log('date----', new Date(result[0].date))

      const transformed_1 = transformDataHandler(result[0].data);
      const transformed_2 = transformDataHandler(result[1].data); 
      const transformed_3 = transformDataHandler(result[2].data);  

      const newData = {
        '1': transformed_1, 
        '2': transformed_2, 
        '3': transformed_3, 
      };
      setPrices(newData); 
      console.log('result', result); 
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }


  const transformDataHandler = (data: LFResponse): any => {
    const newData: any = {gold: {}, silver: {}, platinum: {}, palladium: {}}; 
    data.LFValues.LFValue.forEach(item => {
      if(item.PM) {
        newData[shortSymbol[item.Symbol]].pm = item.PM; 
      }
      if(item.AM) {
        newData[shortSymbol[item.Symbol]].am = item.AM; 
      }
    });   
    return newData; 
  }

  useEffect(() => {
    // getData(); 
    getDataHandler(); 
  }, []);

/*    const formatDate = (date: any) => {
    return date.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
  };  */  

  const formatDate = (dateString: string) => {
    // Define an array for month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    // Split the input 'YYYY-MM-DD' string to get year, month, and day
    const [year, month, day] = dateString.split('-');
  
    // Convert the month from 'MM' to a month name using the months array
    const formattedMonth = months[parseInt(month, 10) - 1];
  
    // Return the formatted date as 'Oct 16, 2024'
    return `${formattedMonth} ${day}, ${year}`;
  };
  

  const PriceCell = ({ am, pm, isLast = false, isRight=false }: any) => (
    <td className={clsx("py-2  dark:text-white", !isLast && 'border-b', isSm ? 'text-[12px]' : 'text-[12px]', isRight ? 'text-right' : 'px-1 text-center')} >
      <span className="text-[12px] font-semibold dark:text-white">AM</span> {humanizePrice(am)}
      <br />
      <span className="text-[12px] font-semibold dark:text-white">PM</span> {humanizePrice(pm)}
    </td>
  );

  const SilverPriceCell = ({ am, isLast = false }: any) => (
    <td className={clsx("py-2 text-center dark:text-white", !isLast && "border-b", isSm ? 'px-1 text-[12px]' : 'px-1 text-[12px]')}>{am.toFixed(2)}</td>
  );

  return (
    <div ref={widgetWrapperRef} className='w-full flex justify-center'> 
      <div className={`${!isSm ? 'py-4 px-6' : 'p-2'} border rounded-lg shadow-lg bg-white text-gray-dark dark:bg-gray-dark dark:text-white ${config?.isTransparent ? 'bg-opacity-0' : 'bg-gray-100 text-gray-800'}`} style={{width: !config?.useContainerSize ? `${config.width}px` : '100%'}}>
        <div className='w-full flex justify-between items-center h-[40px]'>
          <div className={clsx("font-bold", isSm ? 'text-[14px]' : 'text-xl')}>London Fix</div>
          <DateNYTime fontSize='12px'/>
        </div>
      

        <table className="w-full mb-4">
          <thead>
            <tr className="border-b">
              <th className={clsx("py-2 text-left dark:text-white", isSm ? 'pr-2 text-[12px]' : 'pr-2 text-[14px]')}>Date</th>
              <th className={clsx("py-2  dark:text-white", isSm ? 'px-2 text-[12px]' : 'px-4 text-[14px]')}>Gold</th>
              <th className={clsx("py-2  dark:text-white", isSm ? 'px-2 text-[12px]' : 'px-4 text-[14px]')}>Silver</th>
              <th className={clsx("py-2  dark:text-white", isSm ? 'px-2 text-[12px]' : 'px-4 text-[14px]')}>Platinum</th>
              <th className={clsx("py-2 text-right  dark:text-white", isSm ? 'text-[12px]' : 'text-[14px]')}>Palladium</th>
            </tr>
          </thead>
          {prices && <tbody>
            <tr>
              <td className={clsx("py-2 text-left dark:text-white border-b", isSm ? 'pr-2 text-[12px]' : 'pr-2 text-[12px]')}>{dates['1']}</td>
              <PriceCell {...prices['1'].gold} />
              <SilverPriceCell {...prices['1'].silver} />
              <PriceCell {...prices['1'].platinum} />
              <PriceCell {...prices['1'].palladium} isRight/>
            </tr>
            <tr>
              <td className={clsx("py-2 text-left dark:text-white border-b", isSm ? 'pr-2 text-[12px]' : 'pr-2 text-[12px]')}>{dates['2']}</td>
              <PriceCell {...prices['2'].gold} />
              <SilverPriceCell {...prices['2'].silver} />
              <PriceCell {...prices['2'].platinum} />
              <PriceCell {...prices['2'].palladium} isRight/>
            </tr>
            <tr>
              <td className={clsx("py-2 text-left dark:text-white", isSm ? 'text-[12px]' : 'text-[12px]')}>{dates['3']}</td>
              <PriceCell {...prices['3'].gold} isLast/>
              <SilverPriceCell {...prices['3'].silver} isLast />
              <PriceCell {...prices['3'].platinum} isLast/>
              <PriceCell {...prices['3'].palladium} isLast isRight/>
            </tr>
          </tbody>}
        </table>
        <LinkToKitco isDarkMode={config.colorTheme === 'dark'} widgetName='London_Fix_Widget'/>
      </div>
    </div>
  );
};

export default LondonFixWidget;
