import React, { useState, useEffect } from 'react';
import { Switch } from '../@/components/ui/switch';
import ApiService from 'api/apiService';
import { currencyToCountryCode } from '../common/constants';
import { humanizePrice } from '../components/utils';
import LinkToKitco from '../components/Link/LinkToKitco';
import { DateNYTime } from '../components/Date/DateNYTime';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import clsx from 'clsx';
import { DateNYTimeShort } from '../components/Date/DateNYTimeShort';

interface CrossRatesTableProps {
  config: WidgetConfig; 
}

const metals = [
  { symbol: "AU", name: "Gold", color: "#FFD700" },
  { symbol: "AG", name: "Silver", color: "#C0C0C0" },
  { symbol: "PT", name: "Platinum", color: "#E5E4E2" },
  { symbol: "PD", name: "Palladium", color: "#CED0DD" },
];

const currencies = [
  { symbol: "AUD", name: "Australian Dollar", color: "#00008B" },
  { symbol: "BRL", name: "Brazilian Real", color: "#009c3b" },
  { symbol: "CAD", name: "Canadian Dollar", color: "#FF0000" },
  { symbol: "CHF", name: "Swiss Franc", color: "#FF0000" },
  { symbol: "CNY", name: "Chinese Yuan", color: "#DE2910" },
  { symbol: "EUR", name: "Euro", color: "#0052B4" },
  { symbol: "GBP", name: "British Pound", color: "#012169" },
  { symbol: "HKD", name: "Hong Kong Dollar", color: "#BA0C2F" },
  { symbol: "INR", name: "Indian Rupee", color: "#FF9933" },
  { symbol: "JPY", name: "Japanese Yen", color: "#BC002D" },
  { symbol: "MXN", name: "Mexican Peso", color: "#006847" },
  { symbol: "RUB", name: "Russian Ruble", color: "#0039A6" },
  { symbol: "USD", name: "US Dollar", color: "#85bb65" },
  { symbol: "ZAR", name: "South African Rand", color: "#007A4D" },
];



const weightUnits = [
/*   { value: 'oz', label: 'Troy Ounces', conversionFactor: 1 },
  { value: 'g', label: 'Grams', conversionFactor: 31.1034768 },
  { value: 'kg', label: 'Kilograms', conversionFactor: 0.0311034768 },
  { value: 'tael', label: 'Tael', conversionFactor: 0.8333333 },
  { value: 'tola', label: 'Tola', conversionFactor: 2.6666666 }, */

  { value: 'oz', label: 'Troy Ounces', conversionFactor: 1 }, 
  { value: 'g', label: 'Grams', conversionFactor: 0.03215 },  
  { value: 'kg', label: 'Kilograms', conversionFactor: 32.1507466 },
  { value: 'tael', label: 'Teal', conversionFactor: 1.215 },  
  { value: 'tola', label: 'Tola', conversionFactor: 0.375 },  
];

const generateRandomRate = (base: string, quote: string) => {
  const baseRates: any = {
    XAU: 1800, XAG: 25, XPT: 1000, XPD: 2200,
    USD: 1, CAD: 1.25, EUR: 0.85, GBP: 0.75, JPY: 110, CHF: 0.92,
    AUD: 1.35, NZD: 1.45, CNY: 6.5, INR: 75, MXN: 20, BRL: 5.3,
    ZAR: 15, RUB: 75
  };
  return (baseRates[quote] / baseRates[base] * (0.98 + Math.random() * 0.04)).toFixed(4);
};

const CrossRatesTable = ({config}: CrossRatesTableProps) => {
  const [rates, setRates] = useState<any>({});
  const [previousRates, setPreviousRates] = useState<any>({});
  const [selectedUnit, setSelectedUnit] = useState(weightUnits[0]);
  const [showChange, setShowChange] = useState(false);
  const prevRatesRef = React.useRef<any>(null);
  const { widgetWrapperRef } = useSwitchThemeModeInWidget(config.colorTheme);
  const isSmall = config.width < 500;
  const isLarge = config.width > 800;

  const getData = async () => {
    try {
      setPreviousRates(rates);
      const responsePM = await ApiService.getPMBaseData(); 
      const response = await ApiService.getCurrency(); 
      const ratesList:any = {}; 
      const pmMetalsPrices: any = {}; 
      console.log('response', responsePM);
    
      response.Rates.Rate.forEach(item => ratesList[item.Symbol] = item.CtoUSD); 
      responsePM.PreciousMetals.PM.forEach(item => {
        pmMetalsPrices[item.Symbol] = item.Bid
        pmMetalsPrices[`${item.Symbol}_change`] = {change: item?.Change, changePercent: item?.ChangePercentage}

      }); 
      const newRates:any = {};
      console.log('ratesList', ratesList);
      currencies.forEach(currency => {
        newRates[currency.symbol] = {};
        metals.forEach(metal => {
          newRates[currency.symbol][metal.symbol] = (pmMetalsPrices[metal.symbol] * ratesList[currency.symbol])?.toFixed(2);
          newRates[currency.symbol][`${metal.symbol}_change`] = {change: (pmMetalsPrices[`${metal.symbol}_change`]?.change * ratesList[currency.symbol]).toFixed(2) ?? 0, changePercent: (pmMetalsPrices[`${metal.symbol}_change`]?.changePercent * ratesList[currency.symbol]).toFixed(2) ?? 0}; 
        });
      });
      setRates(newRates);
      console.log('newRates', newRates);
      prevRatesRef.current = newRates;
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    getData(); 
    const interval = setInterval(getData, 10000);
    return () => clearInterval(interval);
  }, []);

  const convertRate = (rate: string, unit: { value?: string; label?: string; conversionFactor: any; }) => {
    return (parseFloat(rate) * unit.conversionFactor).toFixed(2);
  };

  const calculateChange = (current: string, previous: string) => {
    if (!previous) return { change: 0, changePercent: 0 };
    const change = parseFloat(current) - parseFloat(previous);
    const changePercent = (change / parseFloat(previous)) * 100;
    return { change: change.toFixed(2), changePercent: changePercent.toFixed(2) };
  };

  return (
    <div ref={widgetWrapperRef} className="font-sans" style={{width: !config?.useContainerSize ? `${config.width}px` : '100%'}}>

      <div className={`border border-gray-200 rounded-lg shadow-lg overflow-hidden bg-white text-gray-dark dark:bg-gray-dark dark:text-white ${config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'} px-2`} >

        <div className={clsx(`p-4 flex justify-between items-center`, isSmall ? 'flex-col' : 'flex-row')}>
          <div className={`font-bold ${config.colorTheme === 'light' ?  'text-gray-800' : 'text-gray-100'} ${isSmall ? 'text-[16px] pb-1' : isLarge ? 'text-[18px]' : 'text-[18px] w-[200px]'}`}>Metals and Currencies Cross Rates</div>
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <label htmlFor="show-change" className={`${isSmall ? 'text-[12px]' : 'text-[14px]'} font-medium ${config.colorTheme === 'light' ?  'text-gray-light_700' : 'text-gray-light_100'}`}>Show Change</label>
               <Switch id="show-change" checked={showChange} onCheckedChange={setShowChange} /> 
            </div>
            <div className="flex items-center">
              <label htmlFor="unit-select" className={clsx("mr-2 font-medium text-gray-light_700 dark:text-gray-light_100", isSmall ? 'text-[12px]' : 'text-[14px]')}>Unit:</label>
              <select
                id="unit-select"
                value={selectedUnit.value}
                onChange={(e) => {
                  const result = weightUnits.find(unit => unit.value === e.target.value); 
                  if(result) {
                    setSelectedUnit(result); 
                  }
                } }
                className={clsx("py-2 rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white outline-none", isSmall ? 'text-[11px] w-[100px] px-1' : 'text-[13px] px-2')}
              >
                {weightUnits.map((unit) => (
                  <option key={unit.value} value={unit.value}>{unit.label}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className={clsx("overflow-x-auto", config.colorTheme === 'dark' ? 'custom-scroll-sm-dark': 'custom-scroll-sm-white', !isSmall ? 'px-3' : '')}>
          <table className="w-full">
            <thead>
              <tr>
                <th className={clsx("p-2 border-b text-left", !isLarge && 'w-[170px]', isSmall && 'bg-white dark:bg-gray-dark sticky left-0')}>
                  <DateNYTimeShort fontSize={isSmall ? '10px' : '11px'}/>
                </th>
                {metals.map(metal => (
                  <th key={metal.symbol} className="p-2 border-b font-bold text-[12px]">
                    <div className="flex items-center justify-end">
                      <div className="w-4 h-4 rounded-full mr-1" style={{ backgroundColor: metal.color }}></div>
                      <div className={clsx('w-auto text-gray-dark dark:text-gray-subtext_light')}>{metal.name}</div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody >
              {currencies.map(currency => (
                <tr key={currency.symbol}>
                  <th className={clsx(`p-2 font-bold text-sm border-y min-w-[170px]`, isSmall && 'bg-white dark:bg-gray-dark sticky left-0')}>
                    <div className="flex items-center space-x-1">
                      <img 
                        // src={`https://flagcdn.com/w20/${currencyToCountryCode[currency.symbol].toLowerCase()}.png`}
                        src={`https://www.kitco.com/flags/${currency.symbol}.png`}
                        alt={`${currency.name} flag`}
                        className="text-[10px] w-[23px] h-[16px]"
                      />
                      <span className={clsx('text-left text-gray-dark dark:text-gray-subtext_light', isSmall ? 'text-[11px]' : 'text-[13px]')}>{currency.name}</span>
                    </div>
                  </th>
                  {metals.map(metal => {
                    const rate = rates[currency.symbol]?.[metal.symbol];
                    const changes = (metal.symbol && rates[currency.symbol]) ? rates[currency.symbol][`${metal.symbol}_change`] : null;
                    // const changes: any = null;
                    const previousRate = prevRatesRef.current ? previousRates[currency.symbol]?.[metal.symbol] : null;
                    // const { change, changePercent } = calculateChange(rate, previousRate);
                    const convertedRate = rate ? convertRate(rate, selectedUnit) : '-';
                  
                    return (
                      <td key={`${currency.symbol}-${metal.symbol}`} className={clsx("p-2 text-right border-y", isSmall ? 'min-w-[120px] text-[10px]' : 'text-[12px]')}>
                        <div className='text-gray-dark dark:text-gray-subtext_light'>{humanizePrice(+convertedRate)}</div>
                        { showChange && changes?.change && (
                          <div className={clsx('text-[10px]', {
                            'text-green-dark dark:text-green-light': parseFloat(`${changes?.change}`) > 0,
                            'text-red-dark dark:text-red-light': parseFloat(`${changes?.change}`) < 0,
                            'text-gray dark:text-gray-subtext_light': parseFloat(`${changes?.change}`) === 0,
                            
                          })}>
                            {changes?.change} ({changes?.changePercent}%)
                           {/*  {change} ({changePercent}%) */}
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={`flex justify-end items-center py-2 px-4  text-sm  my-2 rounded-b-lg`}>
           <LinkToKitco isDarkMode={config.colorTheme === 'dark'} />
      </div>
      </div>

    </div>
  );
};

export default CrossRatesTable;
