import clsx from "clsx";
import React from "react";

interface DateNYTimeProps {
  fontSize?: string;
}

export const DateNYTimeCustomStyle = ({fontSize }: DateNYTimeProps) => {
  const currentDateTime = new Date().toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'America/New_York',
  });

  return (
    <div className={clsx('mb-[2px]')} style={{ fontSize: fontSize ?? '10px'}}>
      {currentDateTime} NY Time
    </div>
  );
};
