import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import W_1 from '../assets/widgets/W-01.png';
import W_2 from '../assets/widgets/W-02.png';
import W_3 from '../assets/widgets/W-03.png';
import W_4 from '../assets/widgets/W-04.png';
import W_5 from '../assets/widgets/W-05.png';
import W_6 from '../assets/widgets/W-06.png';
import logo from '../assets/icons/Yellow- Dark Grey - logo.svg';
import backgroundImage from '../assets/icons/LP-Background.jpg';
import { ChevronLeft } from 'lucide-react';

function Home() {
  const navigate = useNavigate();
  return (
    <div>
      <div className='background-wave'></div>
      <main className='content' style={{  backgroundImage: `url('${backgroundImage}')`, 
  backgroundSize: 'cover',  backgroundPosition: 'center center'}}>
        <img
          src={logo}
          alt='logo'
          style={{ width: 'auto', height: '35px', marginBottom: '30px' }}
        />

        <h1 className='title-main'>Market Insights, Simplified.</h1>
        <p>
          Empower your market insights and financial data with customizable
          trading widgets!
        </p>

        <div className='wrap-links'>
          <a href='#/widgets' className='button'>
            Explore widgets
          </a>
          <a href='#/tutorial/kgx' className='button'>
            Tutorial
          </a>
          <a href='#/contact_us' className='button'>
            Contact Us
          </a>
          <a
            href='https://corp.kitco.com/'
            target='_blank'
            rel='noopener noreferrer'
            className='button'
          >
            About Kitco
          </a>

          <div className='back-link'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='#EEBC4E'
              strokeWidth='1'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='lucide lucide-chevron-left'
            >
              <path d='m15 18-6-6 6-6' />
            </svg>
            <a
              href='https://www.kitco.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Back to Kitco.com
            </a>
          </div>
        </div>

        <div className='widget-grid'>
         {/*  <div className='grid-overlay'></div> */}

          <Link
            className='widget'
            to="/widgets/kgx"
            style={{ left: '0', top: '0' }}
          >
            <img src={W_1} alt='Kitco Global Index' />
            <div className='widget-description'>
              Correlate the relation between the USD Index fluctuations and its impact on the commodities price.
            </div>
          </Link>

          <Link
            className='widget'
            to="/widgets/gold_chart"
            style={{ left: '33.33%', top: '0' }}
          >
            <img src={W_2} alt='Precious Metals Charts' />
            <div className='widget-description'>
              Get visual with our classic commodities charts
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '66.66%', top: '0' }}
            to="/widgets/price_calculator"
          >
            <img src={W_3} alt='Historical Data' />
            <div className='widget-description'>
              Precious metals price calculator, in 14 currencies and 3 units of measure.
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '0', top: '33.33%' }}
            to="/widgets/precious_metals"
          >
            <img src={W_4} alt='News' />
            <div className='widget-description'>
              Get visual with our classic commodities charts.
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '33.33%', top: '33.33%' }}
            to="/widgets/branded_metal_performance"
          >
            <img src={W_5} alt='Calculators' />
            <div className='widget-description'>
              Follow the trend with our historical performance data.
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '66.66%', top: '33.33%' }}
            to="/widgets/gold_bid"
          >
            <img src={W_6} alt='Tickers' />
            <div className='widget-description'>
              Get the latest precious metals spot prices and London Fix.
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '0', top: '66.66%' }}
            to="/widgets/kgx"
          >
            <img src={W_1} alt='Performance Tables' />
            <div className='widget-description'>
              Correlate the relation between the USD Index fluctuations and its impact on the commodities price.  
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '33.33%', top: '66.66%' }}
            to="/widgets/gold_chart"
          >
            <img src={W_2} alt='Single Metal Details' />
            <div className='widget-description'>
              Get visual with our classic commodities charts
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '66.66%', top: '66.66%' }}
            to="/widgets/price_calculator"
          >
            <img src={W_3} alt='Custom Widgets' />
            <div className='widget-description'>
              Precious metals price calculator, in 14 currencies and 3 units of measure.
            </div>
          </Link>
        </div>
      </main>
    </div>
  );
}

export default Home;
