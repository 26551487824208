import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown } from 'lucide-react';
import ApiService from 'api/apiService';
import { categoryBySymbol, shortSymbol } from 'common/constants';

interface CommodityWidgetProps {
  config: WidgetConfig, 
}

const CommodityWidget: React.FC<CommodityWidgetProps>  = ({config}) => {
  const [isTableView, setIsTableView] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('Precious Metals');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [commodities, setCommodities] = useState<any>([]); 
  const containerRef = useRef<any>(null);
  const dropdownRef = useRef<any>(null);

  const categories = ['All', 'Precious Metals', 'Base Metals', 'Energy', 'Crypto (coming soon)'];

  const transformDataHandler = (data: BaseDataI[], energyData: EnergyDataI) => {
    const newData = data.map(item => {
      return { name: shortSymbol[item.Symbol], price: item.Bid, totalChange: item.Change, totalChangePercent: item.ChangePercentTrade, usdWeakness: item.ChangeUSD, usdWeaknessPercent: item.ChangePercentUSD, normalTrading: item.ChangeTrade, normalTradingPercent: item.ChangePercentTrade, category: categoryBySymbol[item.Symbol] }
    }); 
    const oilData = { name: 'Oil', price: energyData.Price, totalChange: energyData.ChangeTrade, totalChangePercent: energyData.ChangePercentTrade, usdWeakness: energyData.ChangeUSD, usdWeaknessPercent: energyData.ChangePercentUSD, normalTrading: energyData.ChangeTrade, normalTradingPercent: energyData.ChangePercentTrade, category: 'Energy' }; 
    return [...newData, oilData]; 
  }

  const getData = async () => {
    try {
      const baseMetal = await ApiService.getBMBaseData(); 
      const precious_metals = await ApiService.getPMBaseData(); 
      const oilData = await ApiService.getOilBaseData(); 
      const data = [...precious_metals.PreciousMetals.PM, ...baseMetal.BaseMetals.PM]; 
      const energyData = oilData.Values.Value;  
      const transformedData = transformDataHandler(data, energyData); 
      setCommodities(transformedData);  
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    getData(); 
    const interval = setInterval(getData, 100000)
    return () => clearInterval(interval)
  }, []); 

  useEffect(() => {
    const updateFontSize = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const fontSize = Math.max(12, Math.min(16, width / 20));
        containerRef.current.style.setProperty('--base-font-size', `${fontSize}px`);
      }
    };

    updateFontSize();
    window.addEventListener('resize', updateFontSize);
    return () => window.removeEventListener('resize', updateFontSize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const ChangeCell = ({ value, percent }: any) => (
    <div className={`text-right ${value >= 0 ? 'text-green-600' : 'text-red-600'}`}>
      <div style={{ fontSize: 'var(--base-font-size)' }}>{value >= 0 ? '▲' : '▼'} ${Math.abs(value)?.toFixed(2)}</div>
      <div style={{ fontSize: 'calc(var(--base-font-size) * 0.8)' }}>{percent?.toFixed(2)}%</div>
    </div>
  );


  const TableView = ({isDarkMode}: {isDarkMode: boolean}) => {
    const filteredCommodities = selectedCategory === 'All' 
      ? commodities 
      : commodities.filter((c: any) => c.category === selectedCategory);

    return (
      <div className="p-2">
        <table style={{ fontSize: 'calc(var(--base-font-size) * 0.9)', width: '100%'}}>
          <thead>
            <tr className={`${isDarkMode ? 'text-white' : 'text-gray-600'}`}>
              <th className="text-left">Commodity</th>
              <th className="text-right">
                <div>USD</div>
                <div>Weakness</div>
              </th>
              <th className="text-right">
                <div>Normal</div>
                <div>Trading</div>
              </th>
              <th className="text-right">
                <div>Total</div>
                <div>Change</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredCommodities.map((commodity: any, index: number) => (
              <tr key={commodity.name} className="border-t">
                <td className="py-1">
                  <div className="font-semibold capitalize">{commodity.name}</div>
                  <div>${commodity?.price?.toFixed(2)}</div>
                </td>
                <td><ChangeCell value={commodity.usdWeakness} percent={commodity.usdWeaknessPercent} /></td>
                <td><ChangeCell value={commodity.normalTrading} percent={commodity.normalTradingPercent} /></td>
                <td><ChangeCell value={commodity.totalChange} percent={commodity.totalChangePercent} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const ToggleSwitch = () => (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input type="checkbox" className="sr-only" checked={isTableView} onChange={() => setIsTableView(!isTableView)} />
        <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
        <div className={`absolute w-6 h-6 bg-white rounded-full shadow -top-1 transition ${isTableView ? 'right-0' : 'left-0'}`}></div>
      </div>
      <div className="ml-3 text-gray-700 font-medium" style={{ fontSize: 'var(--base-font-size)' }}>
        {isTableView ? 'Table' : 'Single'}
      </div>
    </label>
  );

  return (
    <div ref={containerRef} className={`w-full max-w-md shadow-lg rounded-lg overflow-hidden ${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} style={{ fontSize: '14px', width: config.width ?? '' }}>
      <div className="flex justify-between items-center p-2 border-b">
        <div className="relative" ref={dropdownRef}>
          <button 
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="flex items-center"
            style={{ fontSize: 'var(--base-font-size)' }}
          >
            <span className="font-semibold mr-1">{selectedCategory}</span>
            <ChevronDown size={16} />
          </button>
          {isDropdownOpen && (
            <div className="absolute z-10 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                {categories.map((category) => (
                  <button
                    key={category}
                    onClick={() => {
                      setSelectedCategory(category);
                      setIsDropdownOpen(false);
                    }}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                    role="menuitem"
                    disabled={category === 'Crypto (coming soon)'}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* <ToggleSwitch /> */}
      </div>
      {isTableView ? (
        <TableView isDarkMode={config.colorTheme ==='dark'}/>
      ) : (
        <div className="p-4 text-center" style={{ fontSize: 'var(--base-font-size)' }}>
          Single view will be shown (handled externally)
        </div>
      )}
    </div>
  );
};

export default CommodityWidget;
