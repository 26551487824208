import React, { useState, useEffect } from 'react'
import { RefreshCcw, Clock, Sun, Moon, Info, Link } from 'lucide-react'
import { Instrument, VisualizationType, DataObject, ChartPeriod } from './types'
import { instruments, visualizationTypes, chartPeriods } from './constants'
import { getAnalysis, splitText, fetchDataFromAPI, getLimit, humanizePrice } from './utils'
import BubbleVisualization from './BubbleVisualization'
import TextView from './TextView'
import PieChartVisualization from './PieChartVisualization'
import ChartVisualization from './ChartVisualization'
import InfoPopup from './InfoPopup'
import { useWindowSize } from 'usehooks-ts'
import clsx from 'clsx'
import { ChangeExplanation } from './ChangeExplanation'
import LinkToKitco from './Link/LinkToKitco'
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget'
import { useGoogleAnaliticsHandler } from '../hooks/useGoogleAnaliticsHandler'
import AboutKGX from './Informers/AboutKGX'

interface FinancialWidgetProps {
  config: WidgetConfig, 
}

const FinancialWidget: React.FC<FinancialWidgetProps> = ({config}) => {
  const [selectedInstrument, setSelectedInstrument] = useState<Instrument>(instruments[0])
  const [data, setData] = useState<DataObject | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [isInfoOpen, setIsInfoOpen] = useState(false)
  const [containerWidth, setContainerWidth] = useState<any>('600'); 
  const [selectedChartPeriod, setSelectedChartPeriod] = useState(chartPeriods[0])
  const [selectedVisualization, setSelectedVisualization] = useState<VisualizationType>(visualizationTypes[0])
  const isSm = config.width < 400;

  const {widgetWrapperRef} = useSwitchThemeModeInWidget(isDarkMode ? 'dark' : 'light');
  useGoogleAnaliticsHandler('KGX_Advance');

  const {width} = useWindowSize();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const newData = await fetchDataFromAPI(selectedInstrument);
      setData(newData)
      setIsLoading(false)
    }

    fetchData()
    const interval = setInterval(fetchData, 10000)

    return () => clearInterval(interval)
  }, [selectedInstrument])

  useEffect(() => {
    setIsDarkMode(config.colorTheme === 'dark'); 
    setContainerWidth(config.width)
  }, [config]); 

/*   if (isLoading || !data) {
    return <div>Loading...</div>
  } */

const changeColor = (data: any) => {
  if (!data) return ''; 
  if(data?.totalChange === 0) return 'text-gray dark:text-gray-subtext_light';  
  if(data?.totalChange > 0) return 'text-green-dark dark:text-green-light';
  if(data?.totalChange < 0) return 'text-red-dark dark:text-red-light'; 
}


  const renderChartPeriodButtons = () => {
    return chartPeriods.map((period: ChartPeriod) => {
      const { firstHalf, secondHalf } = splitText(period.name)
      return (
        <button
          key={period.id}
          onClick={() => setSelectedChartPeriod(period)}
          className={`
            flex-grow
            px-1 py-2
            mx-0.5 sm:mx-1
            mb-1 
            ${config.width < 400 ? 'text-[12px] min-w-[60px]' : 'text-[14px] min-w-[70px]'} 
            font-medium
            ${selectedChartPeriod.id === period.id
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }
            rounded-lg
            text-center
            h-14 sm:h-auto
            flex flex-col sm:flex-row justify-center items-center
          `}
        >
          {firstHalf}
          <br className="sm:hidden" />
          <span className="hidden sm:inline">&nbsp;</span>
          {secondHalf}
        </button>
      )
    })
  }

  const renderVisualization = (data: any) => {
    switch (selectedVisualization.id) {
      case 'text':
        return <TextView data={data} isDarkMode={isDarkMode} isSm={isSm}/>
      case 'bubbles':
        return <BubbleVisualization data={data} selectedInstrument={selectedInstrument} isDarkMode={isDarkMode} />
      case 'pieCharts':
        return <PieChartVisualization data={data} selectedInstrument={selectedInstrument} isDarkMode={isDarkMode} widthWidget={config.width}/>
      case 'charts':
        return (
          <div>
            <div className={clsx("flex overflow-x-auto mb-4", config.colorTheme === 'dark' ? 'custom-scroll-sm-dark' : 'custom-scroll-sm-white')}>
              {renderChartPeriodButtons()}
            </div>
            <ChartVisualization
              instrument={selectedInstrument}
              period={selectedChartPeriod}
              isDarkMode={isDarkMode}
            />
          </div>
        );
      default:
        return <TextView data={data} isDarkMode={isDarkMode} isSm={isSm}/>
    }
  }

  const renderInstrumentOptions = () => {
    const groupedInstruments = {
      'Precious Metals': instruments.filter(i => i.apiType === 'precious'),
      'Base Metals': instruments.filter(i => i.apiType === 'base'),
      'Energy': instruments.filter(i => i.apiType === 'oil')
    }

    return (
      <>
        {Object.entries(groupedInstruments).map(([group, groupInstruments]) => (
          <optgroup label={group} key={group}>
            {groupInstruments.map(instrument => (
              <option key={instrument.id} value={instrument.id}>
                {instrument.name}
              </option>
            ))}
          </optgroup>
        ))}
      </>
    )
  }

  return (
    <div ref={widgetWrapperRef}>
      <div className={`${!isSm ? 'px-4 pt-6' : 'px-2 pt-4'} relative border rounded-lg shadow-lg bg-white text-gray-dark dark:bg-gray-dark dark:text-white ${config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} style={{width: !config?.useContainerSize ? `${containerWidth}px` : '100%'}}>
      {isInfoOpen && <AboutKGX handleClose={() => setIsInfoOpen(false)} isDarkMode={config.colorTheme === 'dark'}/>}
        {!isSm && <div className='absolute top-2 right-2'>
          <button
              onClick={() => setIsDarkMode(!isDarkMode)}
              className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors mr-2"
            >
              {isDarkMode ? <Sun size={14} /> : <Moon size={14} />}
            </button>
            <button
              onClick={() => setIsInfoOpen(true)}
              className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
            >
              <Info size={14} />
            </button>
        </div>}
        <h2 className={clsx("text-center font-[500]", !isSm ? 'text-[21px]' : 'text-[18px]')}>{'Kitco Global Index (KGX)'}</h2>
        <div className={clsx('text-center font-[500]', !isSm ? 'text-[14px]' : 'text-[12px]')}>Separating Market Values from USD Influence</div>
        <div className="flex justify-center items-center pt-3 mb-4 lg:flex-wrap">
          <select
            value={selectedInstrument.id}
            onChange={(e) => {
              const found = instruments.find(i => i.id === e.target.value)
              if (found) {
                setSelectedInstrument(found)
              }
            }}
            className="mr-2 lg:mr-2 p-1 my-2 text-[14px] rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white h-[32px]"
          >
            {renderInstrumentOptions()}
          </select>

          <select
            value={selectedVisualization.id}
            onChange={(e) => {
              const found = visualizationTypes.find(v => v.id === e.target.value)
              if (found) {
                setSelectedVisualization(found)
              }
            }}
            className="mr-2 lg:mr-2 p-1 text-[14px] rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white h-[32px]"
          >
            {visualizationTypes.map(vizType => (
              <option key={vizType.id} value={vizType.id}>{vizType.name}</option>
            ))}
          </select>
{/*           <div className="hidden sm:flex items-center">
            <Clock size={16} className="mr-2" />
            <span className="text-[14px] mr-2 lg:mr-2">{data?.timestamp}</span>
          </div> */}
{/*           <RefreshCcw size={16} className={`mr-2 lg:mr-2 ${isLoading ? 'animate-spin' : ''}`} /> */}
        </div>

        {data && <div className="text-center mb-4 flex justify-center items-end space-x-2">
          <div className={clsx("font-semibold", {
            "text-[30px] leading-[30px]": config?.width >= 400,  
            "text-[20px] leading-[20px]": config?.width < 400,  
          })}>${humanizePrice(data.price, getLimit(data.symbol))} </div>
          <div className={`${!isSm ? 'text-[20px]' : 'text-[16px]'}  ${changeColor(data)}`}>
            {data.totalChange > 0 ? '+' : ''}{data.totalChange.toFixed(getLimit(data.symbol))} ({data.totalChangePercent > 0 ? '+' : ''}{data.totalChangePercent.toFixed(2)}%)
          </div>
        </div>}

        {/* This will only show on mobile */}
{/*         <div className="sm:hidden text-center mb-2">
          <div className="flex items-center justify-center">
            <Clock size={16} className="mr-2" />
            <span className="text-sm">{data?.timestamp}</span>
          </div>
        </div> */}

{/*         {data && <div className={clsx("mb-2 text-center ", {
          'text-red-dark dark:text-red-light': data.totalChange < 0, 
          'text-green-dark dark:text-green-light': data.totalChange > 0, 
          'text-gray dark:text-gray-subtext_light': data.totalChange === 0,
          'text-[16px]': config?.width >= 400,
          'text-[13px]': config?.width < 400
        })}>
          {(data?.totalChange && data.totalChange !== 0) ? <span>But did {selectedInstrument.name} really go {data.totalChange >= 0 ? 'up' : 'down'} {data.totalChange >= 0 ? '+' : ''} {data.totalChange.toFixed(getLimit(data.symbol))} {`(${data.totalChange >= 0 ? '+' : ''} ${data.totalChangePercent}%)`}?</span> : null}
          {data.totalChange === 0 && data.symbol === 'UR' && <span>Did {selectedInstrument.name} really not change?</span>}
          {((data.usdChange > 0 && (data?.changeTrade && data?.changeTrade > 0) && data.totalChange === 0) || (data.totalChange === 0)) && <span>Did {selectedInstrument.name} really not change?</span>}
        </div>} */}

{/*         {data && <div className={clsx(`mb-4 p-2 rounded bg-gray-light_100 dark:bg-gray-light_700`, config?.width >= 400 ? 'text-[14px]' : 'text-[12px]')}>
          <ChangeExplanation data={data}/>
        </div>} */}

        {data && renderVisualization(data)}

        <div className='flex justify-between items-center py-4'>
           <div className="hidden sm:flex items-center">
            <Clock size={!isSm ? 16 : 14} />
            <span className={clsx(!isSm ? 'text-[14px] ml-2' : 'ml-1 text-[12px]')}>{data?.timestamp}</span>
          </div>

          <LinkToKitco isDarkMode={isDarkMode} isSm={isSm} widgetName='KGX_Advanced'/>
        </div>


       {/*  <InfoPopup isOpen={isInfoOpen} onClose={() => setIsInfoOpen(false)} /> */}
      </div>
    </div>
  )
}

export default FinancialWidget

