import React from 'react'
import { DataObject } from './types'

interface TextViewProps {
  data: DataObject
  isDarkMode: boolean
}

const TextView: React.FC<TextViewProps> = ({ data, isDarkMode }) => {
  const isPositive = data.totalChange >= 0
  const changeColor = isPositive ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'

  // Calculate percentages based on total change
  const usdChangePercent = (data.usdChange / data.totalChange) * data.totalChangePercent
  const marketChangePercent = (data.marketChange / data.totalChange) * data.totalChangePercent

  const formatChange = (change: number, percent: number) => {
    const sign = change >= 0 ? '+' : '-'
    return `${sign}$${Math.abs(change).toFixed(2)} (${sign}${Math.abs(percent).toFixed(2)}%)`
  }

  return (
    <div className={`text-center p-3 rounded-lg ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="flex justify-between text-[12px] mb-1">
        <div className="font-semibold">Change due to USD:</div>
        <div className={data.usdChange >= 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}>
          <span className="font-bold">
            {formatChange(data.usdChange, usdChangePercent)}
          </span>
        </div>
      </div>
      <div className="flex justify-between text-[12px] mb-1">
        <div className="font-semibold">
          {data.marketChange >= 0 ? 'Buyer-Driven Change:' : 'Seller-Driven Change:'}
        </div>
        <div className={data.marketChange >= 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}>
          <span className="font-bold">
            {formatChange(data.marketChange, marketChangePercent)}
          </span>
        </div>
      </div>
      <div className="flex justify-between text-[12px] mt-1">
        <div className="font-semibold">Total Change:</div>
        <div className={changeColor}>
          <span className="font-bold">
            {formatChange(data.totalChange, data.totalChangePercent)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default TextView
