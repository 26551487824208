import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/icons/Yellow- Dark Grey - logo.svg';
import backgroundImage from '../assets/icons/LP-Background.jpg';
import { ChevronLeft } from 'lucide-react';

function Home() {
  const navigate = useNavigate();
  return (
    <div>
      <div className='background-wave'></div>
      <main className='p-[20px] lg:p-[40px] overflow-hidden' style={{  backgroundImage: `url('${backgroundImage}')`, backgroundSize: 'cover',  backgroundPosition: 'center center'}}>
        <img
          src={logo}
          alt='logo'
          className='h-[20px] lg:h-[35px] w-auto mb-[30px]'
        />

        <h1 className='text-[30px] lg:text-[51px] font-bold color-[#EEBC4E]'>Market Insights, Simplified.</h1>
        <p className='py-4 lg:py-0'>
          Empower your market insights and financial data with customizable
          trading widgets!
        </p>

        <div className='wrap-links'>
          <a href='#/widgets' className='button'>
            Explore widgets
          </a>
          <a href='#/widgets/tutorial/kgx' className='button'>
            Tutorial
          </a>
          <a href='#/widgets/contact_us' className='button'>
            Contact Us
          </a>
          <a
            href='https://corp.kitco.com/'
            target='_blank'
            rel='noopener noreferrer'
            className='button'
          >
            About Kitco
          </a>

          <div className='back-link'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='#EEBC4E'
              strokeWidth='1'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='lucide lucide-chevron-left'
            >
              <path d='m15 18-6-6 6-6' />
            </svg>
            <a
              href='https://www.kitco.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Back to Kitco.com
            </a>
          </div>
        </div>

        <div className='widget-grid mt-[50px] ml-[10px] lg:mt-[10px] 2xl:mt-[10px] lg:ml-[-20px] 2xl:-ml-[40px]'>
         {/*  <div className='grid-overlay'></div> */}

          <Link
            className='widget'
            to="/widgets/kgx"
            style={{ left: '0', top: '0' }}
          >
            <img className='shadow-lg' src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/Homepage%20Widgets/KGX-Advanced.jpg'} alt='Kitco Global Index' />
            <div className='widget-description'>
              Correlate the relation between the USD Index fluctuations and its impact on the commodities price.
            </div>
          </Link>

          <Link
            className='widget'
            to="widgets/precious_metals"
            style={{ left: '33.33%', top: '0' }}
          >
            <img className='shadow-lg' src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/Homepage%20Widgets/Precious-Metals-Charts.jpg'} alt='Precious Metals Charts' />
            <div className='widget-description'>
              Get visual with our classic commodities charts.
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '66.66%', top: '0' }}
            to="widgets/spot_price"
          >
            <img className='shadow-lg'  src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/Homepage%20Widgets/Spot-Price-Widget.jpg'} alt='Historical Data' />
            <div className='widget-description'>
              Get the latest precious metals spot prices and London Fix.
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '0', top: '33.33%' }}
            to="/widgets/gold_chart"
          >
            <img className='shadow-lg' src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/Homepage%20Widgets/Three-Days-Charts.jpg'} alt='News' />
            <div className='widget-description'>
              Get visual with our classic commodities charts.
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '33.33%', top: '33.33%' }}
            to="widgets/price_calculator"
          >
            <img  className='shadow-lg' src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/Homepage%20Widgets/Price-Calculator.jpg'} alt='Calculators' />
            <div className='widget-description'>
              Precious metals price calculator, in 14 currencies and 3 units of measure.
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '66.66%', top: '33.33%' }}
            to="/widgets/news"
          >
            <img className='shadow-lg'  src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/Homepage%20Widgets/News-Widget.jpg'} alt='Tickers' />
            <div className='widget-description'>
               Keep informed about the latest market news.
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '0', top: '66.66%' }}
            to="/widgets/london_fix"
          >
            <img className='shadow-lg' src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/Homepage%20Widgets/London-Fix-Widget.jpg'} alt='Performance Tables' />
            <div className='widget-description'>
              Get the latest precious metals spot prices and London Fix.  
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '33.33%', top: '66.66%' }}
            to="/widgets/cross_rates"
          >
            <img className='shadow-lg' src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/Homepage%20Widgets/Kitco-Cross-Rates-Table-Widget.jpg'} alt='Single Metal Details' />
            <div className='widget-description'>
              Get the latest precious metals spot prices and London Fix.
            </div>
          </Link>

          <Link
            className='widget'
            style={{ left: '66.66%', top: '66.66%' }}
            to="/widgets/kgx_commodity"
          >
            <img  className='shadow-lg' src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/Homepage%20Widgets/KGX-Table.jpg'} alt='Custom Widgets' />
            <div className='widget-description'>
              Correlate the relation between the USD Index fluctuations and its impact on   the commodities price.
            </div>
          </Link>
        </div>
      </main>
    </div>
  );
}

export default Home;
