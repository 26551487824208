import { bucketUrl } from "./constants";

const widgetUrl = process.env.REACT_APP_ENV === 'staging' ? bucketUrl.DEV : bucketUrl.PROD; 
export const WidgetsConfigList: WidgetConfigListI = {
    financialKgx: {
        container_id: 'widget-container',
        script_link_display: `${widgetUrl}/widget.bundle.js`,
        script_link: `${widgetUrl}/widget.bundle.js`,
        method_name: 'createFinancialWidget'
    },
    commodityTable: {
        container_id: 'widget-container-comodity',
        script_link_display: `${widgetUrl}/widgetComodity.bundle.js`,
        script_link: `${widgetUrl}/widgetComodity.bundle.js`,
        method_name: 'createComodityWidget'
    },
    metalBid: {
        container_id: 'widget-container-metal-bid',
        script_link_display: `${widgetUrl}/widgetMetalBid.bundle.js`,
        script_link: `${widgetUrl}/widgetMetalBid.bundle.js`,
        method_name: 'createMetalBidWidget'
    },
    goldBid: {
        container_id: 'widget_gold_bid',
        script_link_display: `${widgetUrl}/widgetGoldBid.bundle.js`,
        script_link: `${widgetUrl}/widgetGoldBid.bundle.js`,
        method_name: 'createGoldBidWidget'
    },
    compactPrice: {
        container_id: "widget_compact_price",
        script_link_display: `${widgetUrl}/widgetCompactPrice.bundle.js`,
        script_link: `${widgetUrl}/widgetCompactPrice.bundle.js`,
        method_name: "createCompactPriceWidget"
    },
    spotPrice: {
        container_id: "widget_spot_price",
        script_link_display: `${widgetUrl}/widgetSpotPrice.bundle.js`,
        script_link: `${widgetUrl}/widgetSpotPrice.bundle.js`,
        method_name: "createSpotePriceWidget"
    },
    londonFix: {
        container_id: "widget_london_fix",
        script_link_display: `${widgetUrl}/widgetLondonFix.bundle.js`,
        script_link: `${widgetUrl}/widgetLondonFix.bundle.js`,
        method_name: "createLondonFixWidget"
    },
    news: {
        container_id: "widget_news",
        script_link_display: `${widgetUrl}/widgetNews.bundle.js`,
        script_link: `${widgetUrl}/widgetNews.bundle.js`,
        method_name: "createNewsWidget"
    },
    calculator: {
        container_id: "widget_calculator",
        script_link_display: `${widgetUrl}/widgetCalculator.bundle.js`,
        script_link: `${widgetUrl}/widgetCalculator.bundle.js`,
        method_name: "createCalculatorWidget"
    },
    ticker: {
        container_id: "widget_ticker",
        script_link_display: `${widgetUrl}/widgetTicker.bundle.js`,
        script_link: `${widgetUrl}/widgetTicker.bundle.js`,
        method_name: "createTickerWidget",
        width: "100%"
    },
    crossRates: {
        container_id: "widget_cross_rates",
        script_link_display: `${widgetUrl}/widgetCrossRates.bundle.js`,
        script_link: `${widgetUrl}/widgetCrossRates.bundle.js`,
        method_name: "createCrossRatesWidget",
        width: "100%"
    },
    metalPricePerformance: {
        container_id: "widget_metal_price_performance",
        script_link_display: `${widgetUrl}/widgetMetalPricePerformance.bundle.js`,
        script_link: `${widgetUrl}/widgetMetalPricePerformance.bundle.js`,
        method_name: "createMetalPricePerformanceWidget"
    },
    brandedMetalPerformance: {
        container_id: "widget_branded_metal_performance",
        script_link_display: `${widgetUrl}/widgetBrandedMetalPerformance.bundle.js`,
        script_link: `${widgetUrl}/widgetBrandedMetalPerformance.bundle.js`,
        method_name: "createBrandedMetalPerformanceWidget"
    },
    preciousMetalsChart: {
        container_id: "widget_precious_metals",
        script_link_display: `${widgetUrl}/widgetPreciousMetals.bundle.js`,
        script_link: `${widgetUrl}/widgetPreciousMetals.bundle.js`,
        method_name: "createPreciousMetalsWidget"
    },
    baseMetalsCharts: {
        container_id: "widget_base_metals_charts",
        script_link_display: `${widgetUrl}/widgetBaseMetalsCharts.bundle.js`,
        script_link: `${widgetUrl}/widgetBaseMetalsCharts.bundle.js`,
        method_name: "createBaseMetalsChartsWidget"
    }, 
    threeDaysCharts: {
        container_id: "widget_three_days_charts",
        script_link_display: `${widgetUrl}/widgetThreeDaysCharts.bundle.js`,
        script_link: `${widgetUrl}/widgetThreeDaysCharts.bundle.js`,
        method_name: "createThreeDaysChartsWidget"
    },
    NYChart: {
        container_id: "widget_ny_chart",
        script_link_display: `${widgetUrl}/widgetNYChart.bundle.js`,
        script_link: `${widgetUrl}/widgetNYChart.bundle.js`,
        method_name: "createNYChartWidget"
    }

}