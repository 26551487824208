import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown, Info } from 'lucide-react';
import ApiService from '../api/apiService';
import { categoryBySymbol, logoUrl, shortSymbol } from '../common/constants';
import { DateNYTime } from '../components/Date/DateNYTime';
import LinkToKitco from '../components/Link/LinkToKitco';
import { humanizePrice } from '../components/utils';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import clsx from 'clsx';
import { DateNYTimeShort } from '../components/Date/DateNYTimeShort';
import AboutKGX from '../components/Informers/AboutKGX';
import { useGoogleAnaliticsHandler } from '../hooks/useGoogleAnaliticsHandler';

interface CommodityWidgetProps {
  config: WidgetConfig, 
}

const CommodityWidget: React.FC<CommodityWidgetProps>  = ({config}) => {
  const [isTableView, setIsTableView] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('Precious Metals');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [commodities, setCommodities] = useState<any>([]); 
  const containerRef = useRef<any>(null);
  const dropdownRef = useRef<any>(null);
  const isSm = config.width < 400;

  useGoogleAnaliticsHandler('KGX_Table')

  const categories = ['All', 'Precious Metals', 'Base Metals', 'Energy'];

  const transformDataHandler = (data: BaseDataI[], energyData: EnergyDataI) => {
    const newData = data.map(item => {
      return { name: shortSymbol[item.Symbol], price: item.Bid, totalChange: item.Change, totalChangePercent: item.ChangePercentage, usdWeakness: item.ChangeUSD, usdWeaknessPercent: item.ChangePercentUSD, normalTrading: item.ChangeTrade, normalTradingPercent: item.ChangePercentTrade, category: categoryBySymbol[item.Symbol] }
    }); 
    const oilData = { name: 'Oil', price: energyData.Price, totalChange: energyData.Change, totalChangePercent: energyData.ChangePercentage, usdWeakness: energyData.ChangeUSD, usdWeaknessPercent: energyData.ChangePercentUSD, normalTrading: energyData.ChangeTrade, normalTradingPercent: energyData.ChangePercentTrade, category: 'Energy' }; 
    return [...newData, oilData]; 
  }

  const getData = async () => {
    try {
      const baseMetal = await ApiService.getBMBaseData(); 
      const precious_metals = await ApiService.getPMBaseData(); 
      const oilData = await ApiService.getOilBaseData(); 
      const data = [...precious_metals.PreciousMetals.PM, ...baseMetal.BaseMetals.PM]; 
      const energyData = oilData.Values.Value;  
      const transformedData = transformDataHandler(data, energyData); 
      setCommodities(transformedData);  
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    getData(); 
    const interval = setInterval(getData, 100000)
    return () => clearInterval(interval)
  }, []); 

  useEffect(() => {
    const updateFontSize = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const fontSize = Math.max(12, Math.min(16, width / 20));
        containerRef.current.style.setProperty('--base-font-size', `${fontSize}px`);
      }
    };

    updateFontSize();
    window.addEventListener('resize', updateFontSize);
    return () => window.removeEventListener('resize', updateFontSize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getToFixedOrder = (category: string): number => {
     return category === 'Base Metals' ? 4 : 2; 
  }

  const ChangeCell = ({ category, value, percent = 0}: any) => (
    <div className={clsx('text-center', {
      'text-green-dark dark:text-green-light': value > 0,
      'text-red-dark dark:text-red-light': value < 0,
      'text-gray dark:text-gray-subtext_light': value === 0,
    })} >
      <div className={clsx(isSm ? 'text-[12px]' : 'text-[13px]')}>{value >= 0 ? '▲' : '▼'} ${Math.abs(value ? value : 0)?.toFixed(getToFixedOrder(category))}</div>
      <div className={clsx(isSm ? 'text-[11px]' : 'text-[12px]')}>{percent?.toFixed(2)}%</div>
    </div>
  );

  const {widgetWrapperRef} = useSwitchThemeModeInWidget(config.colorTheme);


  const TableView = ({isDarkMode}: {isDarkMode: boolean}) => {
    const filteredCommodities = selectedCategory === 'All' 
      ? commodities 
      : commodities.filter((c: any) => c.category === selectedCategory);
  
    return (
      <div className="p-2">
        <table style={{ fontSize: isSm ? '12px' : 'calc(var(--base-font-size) * 0.9)', width: '100%'}}>
          <thead className='text-[13px]'>
            <tr className=''>
              <th></th>
              <th className='border-b font-[500] text-right'>Change</th>
              <th className='border-b font-[500] text-left'>due to:</th>
              <th></th> 
              
             </tr>
            <tr className={'text-gray-dark dark:text-white'}>
              <th className="text-left font-[500]">
                <div className='text-[14px]'>Commodities</div>
                <div><DateNYTimeShort fontSize='10px'/> </div>
                
               </th>
              <th className="text-center font-[500]">
                <div>USD</div>
                <div>Strength</div>
              </th>
              <th className="text-center font-[500]">
                <div>Normal</div>
                <div>Trading</div>
              </th>
              <th className="text-center font-[500]">
                <div>Total</div>
                <div>Change</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredCommodities.map((commodity: any, index: number) => (
              <tr key={commodity.name} className="border-y">
                <td className="py-1 text-gray-dark dark:text-white">
                  <div className="font-semibold capitalize">{commodity.name}</div>
                  <div>${humanizePrice(commodity?.price, getToFixedOrder(commodity.category))}</div>
                </td>
                <td><ChangeCell category={commodity.category} value={commodity.usdWeakness} percent={commodity.usdWeaknessPercent} /></td>
                <td><ChangeCell category={commodity.category} value={commodity.normalTrading} percent={commodity.normalTradingPercent} /></td>
                <td><ChangeCell category={commodity.category} value={commodity.totalChange} percent={commodity.totalChangePercent} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const ToggleSwitch = () => (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input type="checkbox" className="sr-only" checked={isTableView} onChange={() => setIsTableView(!isTableView)} />
        <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
        <div className={`absolute w-6 h-6 bg-white rounded-full shadow -top-1 transition ${isTableView ? 'right-0' : 'left-0'}`}></div>
      </div>
      <div className="ml-3 text-gray-700 font-medium" style={{ fontSize: 'var(--base-font-size)' }}>
        {isTableView ? 'Table' : 'Single'}
      </div>
    </label>
  );

  return (
    <div ref={widgetWrapperRef} className='w-full flex justify-center'>
    
    <div ref={containerRef} className={`relative border rounded-lg shadow-lg ${(config.colorTheme ==='dark') ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-white'}`} style={{ fontSize: '14px', width: !config?.useContainerSize ? `${config.width}px` : '100%'}}>
    {isOpen && <AboutKGX handleClose={() => setIsOpen(false)} isDarkMode={config.colorTheme === 'dark'}/>}

      <div className="flex justify-between items-center p-2 border-b ">
        <div className="relative w-full" ref={dropdownRef}>
          <div className='w-full flex justify-between items-center'>
            <img src={config.colorTheme === 'dark' ? logoUrl.dark : logoUrl.light} alt="logo" className={`mr-2 ${isSm ? 'h-[16px]' : 'h-[24px]'}`} />
            <button 
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex items-center"
              style={{ fontSize: isSm ? '12px' : '18px' }}
            >
              <span className="font-semibold mr-1">{selectedCategory}</span>
              <ChevronDown size={16} />
            </button>
          </div>

          {isDropdownOpen && (
            <div className="absolute right-0 mt-1 w-48 rounded-md shadow-lg bg-white dark:bg-gray-light_200 ring-1 ring-black ring-opacity-5 z-50">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                {categories.map((category) => (
                  <button
                    key={category}
                    onClick={() => {
                      setSelectedCategory(category);
                      setIsDropdownOpen(false);
                    }}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                    role="menuitem"
                    disabled={category === 'Crypto (coming soon)'}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* <ToggleSwitch /> */}
      </div>
      {isTableView ? (
        <TableView isDarkMode={config.colorTheme ==='dark'}/>
      ) : (
        <div className="p-4 text-center" style={{ fontSize: 'var(--base-font-size)' }}>
          Single view will be shown (handled externally)
        </div>
      )}
      <div className='pb-2 px-2 pt-1'>
         {/* <LinkToKitco isDarkMode={config.colorTheme === 'dark'}/> */}
         <div className='flex items-center space-x-2'>
         <div className={!isSm ? 'text-[18px]' : 'text-[14px]'}>KGX</div>
         <div className={!isSm ? 'text-[12px]' : 'text-[10px]'}>
          Separating Market Values from USD influence
        </div> 
        <Info size={16} onClick={() => setIsOpen(true)} className='cursor-pointer'/>
      </div>
      <div className='w-full flex justify-end'>
         <LinkToKitco isDarkMode={config.colorTheme === 'dark'} widgetName='KGX_Table'/>
      </div>
     
      </div>
      
     </div>
    </div>
  );
};

export default CommodityWidget;
