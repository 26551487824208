import ApiService from 'api/apiService';
import { DateNYTime } from '../components/Date/DateNYTime';
import React, { useState, useEffect } from 'react';
import LinkToKitco from '../components/Link/LinkToKitco';
import { humanizePrice } from '../components/utils';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import clsx from 'clsx';

interface CompactPriceProps {
  config: WidgetConfig, 
}

const CompactGoldPriceWidget = ({config}: CompactPriceProps) => {
  const [goldData, setGoldData] = useState({
    price: 0,
    change: 0,
    changePercent: 0
  });
  const [currentDateTime, setCurrentDateTime] = useState('');
  const {widgetWrapperRef} = useSwitchThemeModeInWidget(config.colorTheme)

  const getData = async () => {
    try {
      const response = await ApiService.getCertainPM('AU'); 
      const newData = {
        price: response.PreciousMetals.PM.Bid, 
        change: response.PreciousMetals.PM.Change, 
        changePercent: response.PreciousMetals.PM.ChangePercentage, 
      }
      setGoldData(newData); 
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    getData(); 
    const now = new Date();
    setCurrentDateTime(now.toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'America/New_York'
    }));
    const interval = setInterval(getData, 100000); 
    return () => clearInterval(interval);
  }, []);

  return (
    <div ref={widgetWrapperRef} className='w-full flex justify-center'>
      <div className={`border border-gray-300 p-1 text-xs bg-white text-gray-dark dark:bg-gray-dark dark:text-white ${config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} style={{ width: !config?.useContainerSize ? `${config.width}px` : '100%' }}>
        <div className="text-gray-subtext_dark dark:text-gray-subtext_light flex w-full justify-center"><span className='pr-1 text-[10px]'>Spot Prices as of </span><DateNYTime/></div>
        <div className='flex items-center'>
          <div className='px-1'>
            <img src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/ShortLogo.svg'} alt='logo' className="w-[35px] h-[35px]"/>
          </div>
          
          <div className="flex-grow">
            <div className="flex items-center justify-between mt-1">
              <span className="font-bold">Gold</span>
              <span className="font-bold text-[18px]">{humanizePrice(goldData.price)}</span>
              <span className={clsx('', {
                  'text-green-dark dark:text-green-light': goldData.change > 0,
                  'text-red-dark dark:text-red-light': goldData.change < 0,
                  'text-gray dark:text-gray-subtext_light': goldData.change === 0,
                })} >
                {goldData.change >= 0 ? '+' : '-'}${Math.abs(goldData.change).toFixed(2)} ({goldData.changePercent.toFixed(2)}%)
              </span>
            </div>
          </div>
        </div>
        <LinkToKitco isDarkMode={config.colorTheme === 'dark'} isShowLogo={false}/>
      </div>
    </div>
  
  );
};

export default CompactGoldPriceWidget;
