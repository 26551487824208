export const Path = {
    KGX: 'kgx', 
    MINI_KGX: 'kgx_mini',
    COMODITY: 'comodity',
    FINANCIAL_TICKER: 'financial_ticker', 
    CROSS_RATES: 'cross_rates', 
    GOLD_CHART: 'gold_chart',
    NEWS: 'news', 
    PRECIOUS_METALS: 'precious_metals',
    METAL_BID: 'metal_bid', 
    METAL_BID_1: 'metal_bid_1', 
    BRANDED_METAL_PERFORMANCE: 'branded_metal_performance',
    MINI_KGX_BID: 'mini_kgx_bid', 
    GOLD_BID: 'gold_bid',
    METAL_PRICE_PERFORMANCE: 'metal_price_performance',
    PRICE_CALCULATOR: 'price_calculator',   
    SMALL_GOLD_PRICE: 'small_gold_price',
    LONDON_FIX: 'london_fix',
    SPOT_PRICE: 'spot_price',
    CONTACT_US: 'contact_us', 
    TUTORIAL: 'tutorial/kgx', 
    TUTORIAL_CALCULATOR  : 'tutorial/calculator',
    TUTORIAL_BRANDED_PERFORMANCE : 'tutorial/branded_performance',
    ABOUT_KITCO: 'about_kitco',
    HOME: '/',
    WIDGETS: 'widgets'
}

export const shortSymbol: any = {
    'AU': 'gold',
    'AG': 'silver',
    'PT': 'platinum',
    'PD': 'palladium',
    'CU': 'copper',
    'NI': 'nickel',
    'AL': 'aluminum',
    'ZN': 'zinc',
    'PB': 'lead',
    'UR': 'uranium'
}

export const categoryBySymbol: any = {
    'AU': 'Precious Metals',
    'AG': 'Precious Metals',
    'PT': 'Precious Metals',
    'PD': 'Precious Metals',
    'CU': 'Base Metals',
    'NI': 'Base Metals',
    'AL': 'Base Metals',
    'ZN': 'Base Metals',
    'PB': 'Base Metals', 
    'UR': 'Base Metals',
    'CL': 'Energy', 
} 

export const unitsNames: any = {
  'oz': 'OUNCE',
  'g': 'GRAM',  
  'kg': 'KILO', 
}

export const bucketUrl = {
    DEV: 'https://storage.googleapis.com/kitco-com-widgets-dev', 
    PROD: 'https://storage.googleapis.com/kitco-widgets-storage'
}