import ApiService from 'api/apiService';
import clsx from 'clsx';
import { DateNYTime } from '../components/Date/DateNYTime';
import LinkToKitco from '../components/Link/LinkToKitco';
import { generatePriceChangeColorsWithTheme, humanizePrice } from '../components/utils';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import React, { useState, useEffect } from 'react';
import { useGoogleAnaliticsHandler } from '../hooks/useGoogleAnaliticsHandler';

interface WidgetProps {
  config: WidgetConfig; 
}

interface AssetsI {
  symbol: string; 
  name: string; 
  icon: string; 
  color: string; 
  price: number; 
  change: number; 
  changePercent: number; 
  darkColor: string;
  url?: string;
  iconUrl?: string;
}

interface ItemProps {
  asset: AssetsI; 
  theme: 'light' | 'dark';  
  gsRatio?: string;
}

const assetsDefault = [
  { symbol: 'AU', name: 'Gold', icon: 'Au', color: '#FFD700', darkColor: '#aea675', url: 'https://www.kitco.com/charts/gold' },
  { symbol: 'AG', name: 'Silver', icon: 'Ag', color: '#C0C0C0', darkColor: '#8f8e86', url: 'https://www.kitco.com/charts/silver' },
  { symbol: 'PT', name: 'Platinum', icon: 'Pt', color: '#E5E4E2', darkColor: '#2c7d26', url: 'https://www.kitco.com/charts/platinum'  },
  { symbol: 'PD', name: 'Palladium', icon: 'Pd', color: '#CED0DD', darkColor: '#23625f', url: 'https://www.kitco.com/charts/palladium'  },
  { symbol: 'GSR', name: 'Gold / Silver Ratio', icon: '%', color: '#2490ca', darkColor: '#a18f2a' },
  { symbol: 'BTC', name: 'Bitcoin', icon: '₿', iconUrl: 'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/bitcoin.png', color: '#F7931A', darkColor: '#F7931A', url: 'https://www.kitco.com/price/crypto/bitcoin'  },
  { symbol: 'ETH', name: 'Ethereum', icon: 'Ξ', iconUrl: 'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/icons-ethereum-48.png', color: '#627EEA', darkColor: '#186db7', url: 'https://www.kitco.com/price/crypto/ethereum'  },
];

const TickerItem = ({ asset, theme, gsRatio }: ItemProps) => {

  const formatChange = (change: number, percent: number) => {
    const sign = change >= 0 ? '+' : '-'
    return `${sign}$${Math.abs(change).toFixed(2)} (${sign}${Math.abs(percent).toFixed(2)}%)`
  }

  const handleClick = () => {
    if(asset?.url) {
      window.open(asset?.url, '_blank');
    }
  }
  return (
    <div className='flex items-center justify-center px-4 border-r border-gray-200 last:border-r-0 whitespace-nowrap cursor-pointer h-[40px]' onClick={handleClick}>
      <div className='flex items-center font-bold mr-2'>
        {!asset?.iconUrl && <div
          className={'w-[24px] h-[24px] mr-2 rounded-full flex items-center justify-center text-white text-xs font-bold'}
          style={{ backgroundColor: theme === 'light' ? asset.color : asset.darkColor }}
        >
          {asset?.icon}
        </div>}
        {asset?.iconUrl && <div className='w-[24px] h-[24px] mr-2'><img src={asset.iconUrl} alt='icon' className='h-full w-full'/></div>  }
        {asset?.name}
      </div>
      {asset?.symbol !== 'GSR' && <span className='font-bold mr-2'>${humanizePrice(asset?.price)}</span>}
      {asset?.symbol !== 'GSR' ? <span className={clsx(`text-sm`, generatePriceChangeColorsWithTheme(asset?.change))}>
        {formatChange(asset?.change, asset?.changePercent)}
      </span> : <span className='font-bold'>{gsRatio}</span>}
    </div>
  );
};

const KitcoFinancialTicker = ({config}: WidgetProps) => {
  const [assets, setAssets] = useState<AssetsI[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [ratioGS, setRatioGS] = useState('');

  useGoogleAnaliticsHandler('Precious_Metals_Ticker');
  const {widgetWrapperRef} = useSwitchThemeModeInWidget(config.colorTheme);

  const getData = async () => {
    try {
      const data = ['AU', 'AG', 'PT', 'PD', 'CL', 'BTC', 'ETH', 'RH']; 
      const newAssets: any = {}; 
      data.forEach(item => {
        newAssets[item] = {price: '', change: '', changePercent: ''}; 
      }); 

      const PMResponse = await ApiService.getPMBaseData(); 
      const energyResponse = await ApiService.getOilBaseData(); 
      const cryptoResponse = await ApiService.getCrypto('BTC,ETH'); 

      PMResponse.PreciousMetals.PM.forEach(item => {
        newAssets[item.Symbol].price = item.Bid; 
        newAssets[item.Symbol].change = item.Change; 
        newAssets[item.Symbol].changePercent = item.ChangePercentage; 
        
      }); 

      const oilData = energyResponse.Values.Value; 
      newAssets[oilData.Symbol].price = oilData.Price; 
      newAssets[oilData.Symbol].change = oilData.Change; 
      newAssets[oilData.Symbol].changePercent = oilData.ChangePercentage; 

      cryptoResponse?.results?.data_set?.forEach(item => {
        newAssets[item.symbol].price = item.rates[0].high; 
        newAssets[item.symbol].change = item.rates[0].change;
        newAssets[item.symbol].changePercent = item.rates[0].change_percent;
      });


      const GSRatio = (newAssets.AU.price / newAssets.AG.price).toFixed(2);
      setRatioGS(GSRatio);

      const result = assetsDefault.map(item => ({...item, ...newAssets[item.symbol]})); 
      setAssets(result);
      
    } catch (error) {
      console.log('error', error); 
    }
  }
  

  useEffect(() => {
    getData(); 
    const interval = setInterval(getData, 100000)
    return () => clearInterval(interval)
  }, []); 

  return (
  <div ref={widgetWrapperRef} className='relative overflow-hidden border'>
    <div 
    onMouseEnter={() => setIsOpen(true)}  
    onMouseLeave={() => setIsOpen(false)}  
    className={`absolute left-0 top-0 w-[225px] h-[40px] flex flex-col items-start justify-center pl-[45px] bg-white text-gray-dark dark:bg-gray-dark dark:text-white border-r z-40 text-left transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0 opacity-100' : '-translate-x-full'}`}>
      <LinkToKitco isDarkMode={config.colorTheme === 'dark'} isShowLogo={false} isSm isBold widgetName='Precious_Metals_Ticker'/>
      <DateNYTime fontSize='10px'/>
  </div>
    <div  className={'font-sans overflow-x-auto scrollbar-hide'} style={{ width: !config?.useContainerSize ? `${config.width}px` : '100%' }}>

      <div className={` bg-white h-[40px] text-gray-dark dark:bg-gray-dark dark:text-white ${assets.length ? 'w-max' : 'w-full'}  ${config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} >

        <div className="w-full flex">
            <div 
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)} 
              className="w-[40px] flex items-center justify-center bg-white text-gray-dark dark:bg-gray-dark dark:text-white sticky left-0 z-50 border-r">
                <img src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/ShortLogo.svg'} alt='logg' className='h-[30px]'/>
            </div>


              {assets.length ? (
                <div className="ticker-track" >
                  <div className="ticker-content flex">
                    {assets.map((asset) => (
                      <TickerItem gsRatio={ratioGS} key={asset.symbol} asset={asset} theme={config.colorTheme} />
                    ))}
                    {/* Duplicate items for seamless looping */}
                    {assets.map((asset) => (
                      <TickerItem gsRatio={ratioGS}  key={asset.symbol + '-duplicate'} asset={asset} theme={config.colorTheme} />
                    ))}
                  </div>
                </div>
              ) : null}
          </div>
      </div>
    </div>
    </div>
  );
};

export default KitcoFinancialTicker;
