import React from 'react'
import { DataObject } from './types'
import { categoryBySymbol, shortSymbol, shortSymbolFormatted } from '../common/constants'
import { getLimit } from './utils'
import clsx from 'clsx'

interface TextViewProps {
  data: DataObject
  isDarkMode: boolean
  isSm: boolean
}

const TextView: React.FC<TextViewProps> = ({ data, isDarkMode, isSm }) => {
  // Calculate percentages based on total change
  const usdChangePercent = (data.usdChange / data.totalChange) * data.totalChangePercent
  const marketChangePercent = (data.marketChange / data.totalChange) * data.totalChangePercent

  const formatChange = (change: number, percent: number, symbol: string) => {
    const sign = change >= 0 ? '+' : ''
    return `${sign} ${Math.abs(change).toFixed(getLimit(symbol))}`
  }

  return (
    <div className={`text-center p-3 rounded-lg bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-white`}>
      <div className="flex justify-between mb-1">
        <div className={clsx("font-semibold w-1/2 text-left lg:w-auto", !isSm ? 'text-[14px]' : 'text-[11px]')}>{data?.usdChange !== 0 ? `Change due to weakening of USD` : `Price has no Change`}</div>
        <div className={clsx('', {
          'text-green-dark dark:text-green-light': data.usdChange > 0,
          'text-red-dark dark:text-red-light': data.usdChange < 0,
          'text-gray dark:text-gray-subtext_light': data.usdChange === 0,
          'text-[16px]': !isSm, 
          'text-[14px]': isSm,  
        })}>
          <span className="font-bold">
            {data.symbol !== 'UR' ? formatChange(data.usdChange, data.totalChangePercent, data.symbol) : categoryBySymbol[data?.symbol] === 'Base Metals' ? '0.0000' : '0.00'}
          </span>
        </div>
      </div>
      <div className="flex justify-between mb-1">
        <div className={clsx("font-semibold w-1/2 text-left lg:w-auto", !isSm ? 'text-[14px]' : 'text-[11px]')}>
          {data?.marketChange > 0 && `Change due to Predominant Buyers`}
          {data?.marketChange < 0 && `Change due to Predominant Sellers`}
          {data?.marketChange === 0 && `Price has no Change`}
        </div>
        <div className={clsx('', {
          'text-green-dark dark:text-green-light': data.marketChange > 0,
          'text-red-dark dark:text-red-light': data.marketChange < 0,
          'text-gray dark:text-gray-subtext_light': data.marketChange === 0,
          'text-[16px]': !isSm, 
          'text-[14px]': isSm, 
          })}>
          <span className="font-bold">
            {data.marketChange !== 0 ? formatChange(data.marketChange, marketChangePercent, data.symbol) : categoryBySymbol[data?.symbol] === 'Base Metals' ? '0.0000' : '0.00'}
          </span>
        </div>
      </div>
      <div className="flex justify-between mt-1 border-t-2 border-t-white pt-[5px]">
        <div className={clsx("font-semibold w-1/2 text-left lg:w-auto", !isSm ? 'text-[14px]' : 'text-[11px]')}>{`Total Change`}</div>
        <div className={clsx('', {
          'text-green-dark dark:text-green-light': data.totalChange >= 0,
          'text-red-dark dark:text-red-light': data.totalChange < 0,
          'text-gray dark:text-gray-subtext_light': data.totalChange === 0,
          'text-[16px]': !isSm, 
          'text-[14px]': isSm, 
        })}>
          <span className="font-bold">
            {formatChange(data.totalChange, data.totalChangePercent, data.symbol)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default TextView
