import ApiService from 'api/apiService';
import clsx from 'clsx';
import { shortSymbol } from '../common/constants';
import { DateNYTime } from '../components/Date/DateNYTime';
import LinkToKitco from '../components/Link/LinkToKitco';
import { humanizePrice } from '../components/utils';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import React, { useState, useEffect } from 'react';
import { useGoogleAnaliticsHandler } from '../hooks/useGoogleAnaliticsHandler';

interface SpotPriceProps {
  config: WidgetConfig, 
}


const SpotPriceWidget = ({config}: SpotPriceProps) => {
  const [prices, setPrices] = useState<any>(null);
  const [currentDateTime, setCurrentDateTime] = useState('');

  useGoogleAnaliticsHandler('Spot_Price_Widget');
  const {widgetWrapperRef} = useSwitchThemeModeInWidget(config.colorTheme); 

  const getData = async () => {
    try {
      const data: any = { gold: {}, silver: {}, platinum: {}, palladium: {}}; 
      const resultPM = await ApiService.getPMBaseData(); 
      resultPM.PreciousMetals.PM.forEach(item => {
        data[shortSymbol[item.Symbol]] = {price: item.Bid, change: item.Change, changePercent: item.ChangePercentage, high: item.High, low: item.Low}; 
      });  
      setPrices(data); 
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    getData(); 
    const now = new Date();
    setCurrentDateTime(now.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'America/New_York'
    }) + ' New York Time');
    const interval = setInterval(getData, 100000); 
    return () => clearInterval(interval);
  }, []);

  const PriceRow = ({ metal, data }: any) => (
    <tr className="border-b text-xs text-gray-dark dark:text-white">
      <td className="py-2 pr-1 font-semibold text-gray-dark dark:text-white">{metal}</td>
      <td className="py-2 px-1 text-right text-gray-dark dark:text-white">
        <div>{humanizePrice(data.price)}</div>
        <div  className={clsx(`text-[10px]`, {
           'text-green-dark dark:text-green-light': data.change > 0,
           'text-red-dark dark:text-red-light': data.change < 0,
           'text-gray dark:text-gray-subtext_light': data.change === 0,
        })}>
          {data?.change >= 0 ? '▲' : '▼'}{Math.abs(data?.change)?.toFixed(2)} ({data?.changePercent?.toFixed(2)}%)
        </div>
      </td>
      <td className="py-2 px-1 text-right">{humanizePrice(data.high)}</td>
      <td className="py-2 px-1 text-right">{humanizePrice(data.low)}</td>
    </tr>
  );

  return (
    <div ref={widgetWrapperRef} className='w-full flex justify-center'> 
      <div className={clsx(`h-[330px] p-3 flex flex-col justify-between border rounded-lg shadow-lg ${config?.isTransparent ? 'bg-opacity-0' : 'bg-gray-100'}`, {
        'dark:bg-gray-dark dark:text-white': config?.colorTheme === 'dark',
        'bg-white text-gray-dark': config?.colorTheme === 'light',
      })} style={{width: !config?.useContainerSize ? `${config.width}px` : '100%'}}>
        <div>
          <div className='text-[16px] text-center pb-2'>Precious Metal Quotes</div>
          <div className="mb-2">
            <DateNYTime fontSize='12px'/>
          </div>
          
          <table className="w-full border-collapse">
            <thead>
              <tr className="text-xs text-gray-dark dark:text-white">
                <th className="py-1 pr-1 text-left">Metal</th>
                <th className="py-1 px-1 text-right">Price</th>
                <th className="py-1 px-1 text-right">High</th>
                <th className="py-1 px-1 text-right">Low</th>
              </tr>
            </thead>
            {prices && <tbody>
              <PriceRow metal="Gold" data={prices?.gold} />
              <PriceRow metal="Silver" data={prices?.silver} />
              <PriceRow metal="Platinum" data={prices?.platinum} />
              <PriceRow metal="Palladium" data={prices?.palladium} />
       
            </tbody>}
          </table>
        </div>

        <LinkToKitco isDarkMode={config.colorTheme === 'dark'} widgetName='Spot_Price_Widget'/>
      </div>
    </div>
  );
};

export default SpotPriceWidget;
