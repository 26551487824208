export const Path = {
    KGX: 'kgx', 
    MINI_KGX: 'kgx_mini',
    COMODITY: 'comodity',
    COMODITY_KGX: 'kgx_commodity',
    FINANCIAL_TICKER: 'financial_ticker', 
    CROSS_RATES: 'cross_rates', 
    GOLD_CHART: 'gold_chart',
    NEWS: 'news', 
    PRECIOUS_METALS: 'precious_metals',
    BASE_METALS: 'base_metals', 
    NY_CHART: 'ny_chart',
    METAL_BID: 'metal_bid', 
    METAL_BID_1: 'metal_bid_1', 
    BRANDED_METAL_PERFORMANCE: 'branded_metal_performance',
    MINI_KGX_BID: 'mini_kgx_bid', 
    GOLD_BID: 'gold_bid',
    METAL_PRICE_PERFORMANCE: 'metal_price_performance',
    METAL_PRICE_PERFORMANCE_TABLE: 'metal_price_performance_table',
    PRICE_CALCULATOR: 'price_calculator',   
    SMALL_GOLD_PRICE: 'small_gold_price',
    LONDON_FIX: 'london_fix',
    SPOT_PRICE: 'spot_price',
    CONTACT_US: 'contact_us', 
    TUTORIAL: 'tutorial/kgx', 
    TUTORIAL_CALCULATOR  : 'tutorial/calculator',
    TUTORIAL_BRANDED_PERFORMANCE : 'tutorial/branded_performance',
    ABOUT_KITCO: 'about_kitco',
    HOME: '/',
    WIDGETS: 'widgets'
}

export const shortSymbol: any = {
    'AU': 'gold',
    'AG': 'silver',
    'PT': 'platinum',
    'PD': 'palladium',
    "RH": 'rhodium',
    'CU': 'copper',
    'NI': 'nickel',
    'AL': 'aluminum',
    'ZN': 'zinc',
    'PB': 'lead',
    'UR': 'uranium',
    'CL': 'oil',
}

export const shortSymbolFormatted: any = {
    'AU': 'Gold',
    'AG': 'Silver',
    'PT': 'Platinum',
    'PD': 'Palladium',
    'RH': 'Rhodium',
    'CU': 'Copper',
    'NI': 'Nickel',
    'AL': 'Aluminum',
    'ZN': 'Zinc',
    'PB': 'Lead',
    'UR': 'Uranium',
    'CL': 'Crude Oil',
}

export const longNameToSymbolFormatted: any = {
    'Gold': 'AU',
    'Silver': 'AG',
    'Platinum': 'PT',
    'Palladium': 'PD',
    'Rhodium': 'RH',
    'Copper': 'CU',
    'Nickel': 'NI',
    'Aluminum': 'AL',
    'Zinc': 'ZN',
    'Lead': 'PB',
    'Uranium': 'UR',
    'Crude Oil': 'CL',
}



export const categoryBySymbol: any = {
    'AU': 'Precious Metals',
    'AG': 'Precious Metals',
    'PT': 'Precious Metals',
    'PD': 'Precious Metals',
    'RH': 'Precious Metals',
    'CU': 'Base Metals',
    'NI': 'Base Metals',
    'AL': 'Base Metals',
    'ZN': 'Base Metals',
    'PB': 'Base Metals', 
    'UR': 'Base Metals',
    'CL': 'Energy', 
} 

export const unitsNames: any = {
  'oz': 'OUNCE',
  'g': 'GRAM',  
  'kg': 'KILO', 
}

export const bucketUrl = {
    DEV: 'https://storage.googleapis.com/kitco-com-widgets-dev', 
    PROD: 'https://storage.googleapis.com/kitco-widgets-storage'
}
export const currencyToCountryCode: any = {
    USD: "US",
    CAD: "CA",
    EUR: "EU",
    GBP: "GB",
    JPY: "JP",
    CHF: "CH",
    AUD: "AU",
    CNY: "CN",
    INR: "IN",
    MXN: "MX",
    BRL: "BR",
    ZAR: "ZA",
    RUB: "RU",
  };

  export const unitsLongName: any = {
    oz: 'Ounce',
    g: 'Gram',
    kg: 'Kilo'
  }