import { WidgetsConfigList } from "common/widgetsConfig";
import EmbedCode from "components/EmbedCode/EmbedCode";
import ButtonsSection from "components/SettingsWidget/ButtonsSection";
import SettingsWidget from "components/SettingsWidget/SettingsWidget";
import { getFormattedWidth } from "components/utils";
import { useSettingsWidgetHandler } from "hooks/useSettingsWidgetHandler";
import KitcoBrandedMetalPerformance from "widgets/KitcoBrandedMetalPerformance";

function KitcoBrandedMetalPerformancePage() {

    const {
      isContainerChecked,
      isTransparentChecked,
      widthValue,
      theme,
      handleChange,
      handleContainerChecked,
      handleThemeChange,
      handleTrasparentChecked,
      handleResetSettings,
      handleApply,
      handleBlur, 
      config
    } = useSettingsWidgetHandler({ widthDefault: 400 });

    const embedCodeConfig = WidgetsConfigList.brandedMetalPerformance;

  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
      <div className='text-[30px] pb-4 mulish-600 text-silver'>Historical Performance</div>
      <div className='w-full lg:w-1/2 flex flex-col items-center'>
        <KitcoBrandedMetalPerformance config={config}/>   
      </div>

      <div className='w-full lg:w-1/2 flex flex-col items-center'>

        <SettingsWidget
            widthValue={widthValue}
            theme={theme}
            handleBlur={() => handleBlur(300)}
            isTransparentChecked={isTransparentChecked}
            isContainerChecked={isContainerChecked}
            handleChange={handleChange}
            handleContainerChecked={handleContainerChecked}
            handleThemeChange={handleThemeChange}
            defaultWidth='100%'
            handleTrasparentChecked={handleTrasparentChecked}
          />

          <ButtonsSection
            isTransparentChecked={isTransparentChecked}
            widthValue={widthValue}
            theme={theme}
            handleApply={handleApply}
            embedCodeConfig={embedCodeConfig}
            handleResetSettings={handleResetSettings}
          />

          <EmbedCode
            width={getFormattedWidth(config.useContainerSize, widthValue)}
            isTransparent={isTransparentChecked}
            colorTheme={theme}
            embedCodeConfig={embedCodeConfig}
          />

        </div>
    </div>
  );
}

export default KitcoBrandedMetalPerformancePage;