import React from 'react'
import { Sector } from 'recharts'

interface RadianTooltipProps {
  cx: number
  cy: number
  ex: number
  ey: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  fill: string
  payload: any
  percent: number
  value: number
  name: string
  isDarkMode: boolean
  chartWidth: number
  chartHeight: number
}

const RadianTooltip: React.FC<RadianTooltipProps> = (props) => {
  const RADIAN = Math.PI / 180
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name, isDarkMode, chartWidth, chartHeight } = props

  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)

  // Determine if the tooltip is on the left side of the pie chart
  const isLeftSide = cos < 0

  // Adjust positioning for smaller screens
  const isSmallScreen = chartWidth < 400 // Adjust this threshold as needed

  // Calculate arrow length based on chart size
  const arrowLength = isSmallScreen
    ? Math.min(chartWidth, chartHeight) * 0.005 // Adjust this value for smaller screens
    : Math.min(chartWidth, chartHeight) * 0.10

  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  let mx, my, ex, ey, textAnchor

  if (isSmallScreen) {
    // For small screens, position labels outside the pie chart with straight arrows
    const verticalOffset = outerRadius + arrowLength + 20 // Adjust this value for smaller screens
    ey = cy + verticalOffset * (midAngle > 180 ? 1 : -1)
    ex = cx
    mx = ex
    my = ey
    textAnchor = "middle"
  } else {
    // For larger screens, use the original positioning logic with bent arrows
    mx = cx + (outerRadius + arrowLength * 0.6) * cos
    my = cy + (outerRadius + arrowLength * 0.6) * sin
    ex = mx + (cos >= 0 ? 1 : -1) * (arrowLength * 0.2)
    ey = my
    textAnchor = cos >= 0 ? "start" : "end"
  }

  // Calculate font size based on chart size
  const baseFontSize = isSmallScreen ? Math.max(8, Math.min(12, chartWidth / 40)) : Math.max(10, Math.min(16, chartWidth / 30))

  // Adjust spacing for small screens
  const lineSpacing = isSmallScreen ? baseFontSize * 0.8 : baseFontSize + 6

  // Calculate the center point of the SVG
  const centerX = cx
  const centerY = cy

  // Calculate the scaling factor
  const scale = isSmallScreen ? 0.05 : 0.8

  // Calculate the translation needed to keep the chart centered after scaling
  const translateX = centerX * (1 - scale)
  const translateY = centerY * (1 - scale)

  // Function to determine if the color is red or green
  const isRed = (color: string) => color.toLowerCase() === '#ef4444'
  const isGreen = (color: string) => color.toLowerCase() === '#2e8b57'

  const formatChange = (value: number, percent: number, isPositive: boolean) => {
    const sign = isPositive ? '+' : '-'
    return {
      amount: `${sign}$${Math.abs(value).toFixed(2)}`,
      percentage: `${sign}${Math.abs(percent).toFixed(2)}%`
    }
  }

  const formattedChange = formatChange(value, percent, payload.isPositive)

  return (
    <g transform={`translate(${translateX}, ${translateY}) scale(${scale})`}>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" strokeWidth={isSmallScreen ? 1 : 2} />
      <circle cx={ex} cy={ey} r={isSmallScreen ? 1 : 2} fill={fill} stroke="none" />
      <text
        x={ex}
        y={ey + (midAngle > 180 ? lineSpacing : -lineSpacing * 2.5)}
        textAnchor={textAnchor}
        fill={isDarkMode ? "white" : "#333"}
        fontSize={`${baseFontSize + 2}px`}
        fontWeight="700"
      >
        {name}
      </text>
      <text
        x={ex}
        y={ey + (midAngle > 180 ? lineSpacing * 2 : -lineSpacing * 1.5)}
        textAnchor={textAnchor}
        fill={isDarkMode ? (isRed(fill) || isGreen(fill) ? fill : "white") : fill}
        fontSize={`${baseFontSize + 4}px`}
        fontWeight="700"
      >
        {formattedChange.amount}
      </text>
      <text
        x={ex}
        y={ey + (midAngle > 180 ? lineSpacing * 3 : -lineSpacing * 0.5)}
        textAnchor={textAnchor}
        fill={isDarkMode ? (isRed(fill) || isGreen(fill) ? fill : "white") : fill}
        fontSize={`${baseFontSize + 4}px`}
        fontWeight="700"
      >
        {formattedChange.percentage}
      </text>
    </g>
  )
}

export default RadianTooltip
