import { WidgetsConfigList } from 'common/widgetsConfig';
import EmbedCode from 'components/EmbedCode/EmbedCode';
import FinancialWidget from 'components/FinancialWidget';
import ButtonsSection from 'components/SettingsWidget/ButtonsSection';
import SettingsWidget from 'components/SettingsWidget/SettingsWidget';
import { getFormattedWidth } from 'components/utils';
import { useSettingsWidgetHandler } from 'hooks/useSettingsWidgetHandler';
import { useWindowSize } from 'usehooks-ts';

function FinancialWidgetPage() {
  const {width} = useWindowSize(); 
  const {
    isContainerChecked,
    isTransparentChecked,
    widthValue,
    theme,
    handleChange,
    handleContainerChecked,
    handleThemeChange,
    handleTrasparentChecked,
    handleResetSettings,
    handleApply, 
    handleBlur, 
    config
  } = useSettingsWidgetHandler({ widthDefault: width < 500 ? 350 : 500 });

 const embedCodeConfig = WidgetsConfigList.financialKgx; 
  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
      <div className='text-[30px] mulish-600 text-silver pb-4'>KGX Advanced</div>
      <div className='w-full lg:w-1/2 mx-auto flex flex-col items-center'>
      
        <FinancialWidget config={config}/>

        <SettingsWidget
          widthValue={widthValue}
          theme={theme}
          handleBlur={() => handleBlur(300)}
          isTransparentChecked={isTransparentChecked}
          isContainerChecked={isContainerChecked}
          handleChange={handleChange}
          handleContainerChecked={handleContainerChecked}
          handleThemeChange={handleThemeChange}
          defaultWidth='500'
          handleTrasparentChecked={handleTrasparentChecked}
        />

        <ButtonsSection  
          isTransparentChecked={isTransparentChecked} 
          widthValue={widthValue} 
          handleApply={handleApply}
          theme={theme} 
          embedCodeConfig={embedCodeConfig}
          handleResetSettings={handleResetSettings} />

        <EmbedCode
          width={getFormattedWidth(config.useContainerSize, widthValue)}
          isTransparent={isTransparentChecked}
          colorTheme={theme}
          embedCodeConfig={embedCodeConfig}
        />
      </div>
    </div>
  );
}

export default FinancialWidgetPage;
