import { WidgetsConfigList } from "common/widgetsConfig";
import EmbedCode from "components/EmbedCode/EmbedCode";
import ButtonsSection from "components/SettingsWidget/ButtonsSection";
import SettingsWidget from "components/SettingsWidget/SettingsWidget";
import { getFormattedWidth } from "components/utils";
import { useSettingsWidgetHandler } from "hooks/useSettingsWidgetHandler";
import { useWindowSize } from "usehooks-ts";
import MetalPriceWidget from "widgets/preciousmetals-price-pefromance-widget (1)";

function MetalPricePage() {
    const {width} = useWindowSize(); 
    const {
      isContainerChecked,
      isTransparentChecked,
      widthValue,
      theme,
      handleChange,
      handleContainerChecked,
      handleThemeChange,
      handleTrasparentChecked,
      handleResetSettings,
      handleApply,
      handleBlur, 
      config
    } = useSettingsWidgetHandler({ widthDefault: width < 500 ? 340 : 600 });
  
    const embedCodeConfig = WidgetsConfigList.metalPricePerformance; 

  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
      <div className='text-[30px] mulish-600 text-silver pb-4'>Precious Metals Yearly Performance</div>
 
      <div className='w-full flex flex-col items-center'>
        <MetalPriceWidget config={config}/>
      </div>
    
      <div className='w-full lg:w-1/2 flex flex-col items-center'>

        <SettingsWidget
            widthValue={widthValue}
            theme={theme}
            handleBlur={() => handleBlur(340)}
            isTransparentChecked={isTransparentChecked}
            isContainerChecked={isContainerChecked}
            handleChange={handleChange}
            limitWidth={340}     
            handleContainerChecked={handleContainerChecked}
            handleThemeChange={handleThemeChange}
            defaultWidth='600'
            handleTrasparentChecked={handleTrasparentChecked}
          />

          <ButtonsSection
            isTransparentChecked={isTransparentChecked}
            widthValue={widthValue}
            theme={theme}
            handleApply={handleApply}
            embedCodeConfig={embedCodeConfig}
            handleResetSettings={handleResetSettings}
          />

          <EmbedCode
            width={getFormattedWidth(config.useContainerSize, widthValue)}
            isTransparent={isTransparentChecked}
            colorTheme={theme}
            embedCodeConfig={embedCodeConfig}
          />

       </div>

    </div>
  );
}

export default MetalPricePage;