import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardFooter } from '../@/components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../@/components/ui/tabs';
import LinkToKitco from '../components/Link/LinkToKitco';
import { shortSymbol, shortSymbolFormatted } from '../common/constants';
import ApiService from 'api/apiService';
import clsx from 'clsx';
import { generatePriceChangeColorsWithTheme, humanizePrice } from '../components/utils';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import { useGoogleAnaliticsHandler } from '../hooks/useGoogleAnaliticsHandler';

interface PropsI {
  config: WidgetConfig;
}

const metals = [
  { name: 'Gold', symbol: 'AU', color: '#FF0000' },
  { name: 'Silver', symbol: 'AG', color: '#C0C0C0' },
];

const metalNameFormatted: any = {
  'AU': 'Gold',
  'AG': 'Silver',
  'PT': 'Plati',
  'PD': 'Plad',
}

const NYChartsWidget = ({config}: PropsI) => {
  const [selectedMetal, setSelectedMetal] = useState('AU');
  const [chartUrl, setChartUrl] = useState(''); 
  const [metalData, setMetalData] = useState<BaseDataI | null>(null);  
  const [loading, setLoading] = useState(false);

  useGoogleAnaliticsHandler('New_York_Charts');
  const {widgetWrapperRef} = useSwitchThemeModeInWidget(config.colorTheme); 
  const isSmall = config.width < 400;

  const getData = async (symbol: string) => {
    try {
      setLoading(true); 
      const response = await ApiService.getCertainPM(symbol); 
      if(response) {
        const data = response.PreciousMetals.PM; 
        setMetalData(data);
      }
    } catch (error) {
      console.log('error', error); 
    } finally {
      setLoading(false); 
    } 
  }

  useEffect(() => {
    const lightThemeLink = `https://kds2.kitco.com/kcast/live/${shortSymbolFormatted[selectedMetal]}/438_235/${selectedMetal}_24h_USD_oz.gif`;  
    // const darkThemeLink = `https://d1hecnlhloejvu.cloudfront.net/images/live/${metalNameFormatted[selectedMetal]}.gif`; 
    const darkThemeLink = `https://d1hecnlhloejvu.cloudfront.net/images/live/ny${metalNameFormatted[selectedMetal]}.gif`
    const url = config.colorTheme === 'dark' ? darkThemeLink : lightThemeLink;  
    setChartUrl(darkThemeLink);
    getData(selectedMetal);
  }, [selectedMetal, config.colorTheme]);


  return (
    <div ref={widgetWrapperRef} style={{width: !config?.useContainerSize ? `${config.width}px` : '100%'}}>
      <Card className={`w-full border rounded-lg shadow-lg p-1 text-xs bg-white text-gray-dark dark:bg-gray-dark dark:text-white ${config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`}>
        <CardContent className={clsx(isSmall ? 'p-2' : 'p-4')}>
          <Tabs value={selectedMetal} onValueChange={setSelectedMetal}>
            <TabsList className={clsx("grid grid-cols-2 mb-4 dark:bg-gray", isSmall ? 'gap-1' : 'gap-2')}>
              {metals.map(metal => (
                <TabsTrigger key={metal.symbol} value={metal.symbol} className={'text-xs'}>
                  {metal.name}
                </TabsTrigger>
              ))}
            </TabsList>
            {metals.map(metal => (
              <TabsContent key={metal.symbol} value={metal.symbol}>
                <div className="mt-4">
                  <div className="flex items-center justify-between mb-4">
                    {/* <h2 className="text-xl font-bold">{metal.name} Price</h2> */}
                     <div>
                      <h2 className={clsx("font-bold", isSmall ? 'text-[14px] ' : 'text-[18px]')}>{`24 Hour Spot ${metal.name} (Bid)`}</h2>
                      <p className={clsx("pt-1 text-gray-600 dark:text-white", isSmall ? 'text-[12px]' : 'text-[14px]')}>{`Spot Kitco ${metal.name} (US$/OZ)`}</p>
                    </div>
                    <div className="text-right">
                      <p className={clsx("font-bold", isSmall ? 'text-[14px] ' : 'text-[18px]')}>
                       USD {metalData?.Bid ? humanizePrice(metalData?.Bid, 2) : null}
                      </p>
                      {metalData && <p className={clsx('pt-1', isSmall ? 'text-[12px]' : 'text-[14px]',
                        generatePriceChangeColorsWithTheme(metalData?.Change) 
                      )}>
                        {metalData?.Change.toFixed(2)} ({metalData?.ChangePercentage.toFixed(2)}%)
                      </p>}
                    </div>
                  </div>
                
                <div className='w-full flex justify-center'>
                  <img
                      src={chartUrl}
                      alt={`chart-${selectedMetal}`}
                      // style={{width: config.width}}
                      className="w-full h-full object-fit"/> 
                </div>
     
                </div>
              </TabsContent>
            ))}
          </Tabs>
        </CardContent>
        <CardFooter className="pt-2 pb-4 flex justify-center">
          <LinkToKitco isDarkMode={config.colorTheme === 'dark'} widgetName='New_York_Charts'/>
        </CardFooter>
      </Card>
    </div>
  );
};

export default NYChartsWidget;