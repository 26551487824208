export const codeList = {
    embed_code: (config: any, style: any) => 
      `
        <!-- Kitco Widget BEGIN --> 
        <div class="widget-wrapper" style="margin: 50px auto 0 auto; width: 100%; display: flex; justify-content: center;">
            <div id="${config.container_id}" style="margin-bottom: 60px"></div>
        </div>
          <script src="${config.script_link}"></script>
          <script>
              document.addEventListener('DOMContentLoaded', function() {
                if (typeof window.${config.method_name} === 'function') {
                  const config =  {
                    "width": "${style.width}",
                    "isTransparent": ${style.isTransparent},
                    "colorTheme": "${style.colorTheme}"
                  }    
                  window.${config.method_name}("${config.container_id}", config); 
                } else {
                  console.error('window.${config.method_name} is not a function')
                }
              })
          </script>
          <!-- Kitco Widget END --> 
      `,
      embed_code_copy: (config: any, style: any) => 
        `
          <!-- Kitco Widget BEGIN --> 
          <div class="widget-wrapper" style="margin: 50px auto 0 auto; width: 100%; display: flex; justify-content: center;">
              <div id="${config.container_id}" style="margin-bottom: 60px"></div>
          </div>
            <script src="${config.script_link_display}"></script>
            <script>
                document.addEventListener('DOMContentLoaded', function() {
                  if (typeof window.${config.method_name} === 'function') {
                    const config =  {
                      "width": "${style.width}",
                      "isTransparent": ${style.isTransparent},
                      "colorTheme": "${style.colorTheme}"
                    }    
                    window.${config.method_name}("${config.container_id}", config); 
                  } else {
                    console.error('window.${config.method_name} is not a function')
                  }
                })
            </script>
            <!-- Kitco Widget END --> 
        `
  }; 