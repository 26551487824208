import { bucketUrl } from 'common/constants';
import { codeList } from 'components/EmbedCode/codeList';
import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';

interface PropsI {
  isTransparentChecked: boolean;
  widthValue: number;
  theme: 'light' | 'dark';
  handleResetSettings: () => void;
  handleApply: () => void; 
  embedCodeConfig: EmbedCodeConfig; 
}

function ButtonsSection({
  isTransparentChecked,
  widthValue,
  theme,
  handleResetSettings,
  handleApply, 
  embedCodeConfig
}: PropsI) {
  const [isCopied, setIsCopied] = useState(false);
  const widgetUrl = process.env.REACT_APP_ENV === 'staging' ? bucketUrl.DEV : bucketUrl.PROD; 
  const copyToClipboard = async () => {
    try {
      const text = codeList.embed_code_copy(embedCodeConfig, {
        width: widthValue, 
        isTransparent: isTransparentChecked, 
        colorTheme: theme,
        widgetUrl
      });
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  return (
    <div className='w-full flex justify-between py-[40px]'>
      <button
        className='px-4 py-1 border bg-white rounded-md hover:bg-gray-200'
        onClick={copyToClipboard}
        id='copy-code'
      >
        Copy code
      </button>
      <Tooltip
        anchorSelect='#copy-code'
        place='top'
        content='Copied !'
        isOpen={isCopied}
      />
      <div className='space-x-4'>
        <button
          className='px-4 py-1 bg-white border rounded-md hover:bg-gray-200'
          onClick={handleResetSettings}
        >
          Reset
        </button>
        <button
          className='px-4 py-1 border rounded-md bg-blue-500 text-white hover:bg-blue-200'
          onClick={handleApply}
        >
          Apply
        </button>
      </div>
    </div>
  );
}

export default ButtonsSection;
