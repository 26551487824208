import clsx from 'clsx';
import React from 'react';

interface LinkToKitcoProps {
  isDarkMode: boolean;
  isShowLogo?: boolean;
  isSm?: boolean; 
  isBold?: boolean;
  widgetName?: string;
}

export default function LinkToKitco({ isDarkMode, isShowLogo = true, isSm , isBold, widgetName}: LinkToKitcoProps) {
  const hostName = window.location.hostname; 
  const url  = (widgetName && hostName) ? `https://www.kitco.com/?widget_name=${widgetName}&host_name=${hostName}` : 'https://www.kitco.com/';
  return (
    <div className={`flex items-center justify-center ${!isSm ? 'text-[14px]' : 'text-[13px]'}`}>
        {isShowLogo && <img src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/ShortLogo.svg'} alt='logg' className='h-[20px] pr-1'/>}
        <a
            href={url}
            target='_blank'
            rel='noopener noreferrer'
            className={clsx(`${
            isDarkMode
                ? 'text-white'
                : 'text-gray-dark'
            }`, isBold && 'font-semibold')}
        >
            Powered by <span className={isDarkMode ? 'text-blue-light' : 'text-blue-dark'}>kitco.com</span>
        </a>
    </div>

  );
}
