import { instruments } from '../components/constants';
import { DateNYTime } from '../components/Date/DateNYTime';
import LinkToKitco from '../components/Link/LinkToKitco';
import { Instrument } from '../components/types';
import { humanizePrice } from '../components/utils';
import { Switch } from '../@/components/ui/switch';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

interface PropsI {
  config: WidgetConfig;
}

const sampleData: any = {
  gold: {
    livePrices: {
      USD: { price: 2050.30, change: 15.20, changePercent: 0.75 },
      AUD: { price: 3125.45, change: 22.80, changePercent: 0.73 },
      CAD: { price: 2765.20, change: 20.15, changePercent: 0.73 },
      CHF: { price: 1800.15, change: 12.50, changePercent: 0.70 },
      CNY: { price: 13250.75, change: 95.60, changePercent: 0.73 },
      EUR: { price: 1875.60, change: 13.80, changePercent: 0.74 },
      GBP: { price: 1620.40, change: 11.90, changePercent: 0.74 },
      INR: { price: 152000.50, change: 1120.30, changePercent: 0.74 },
      JPY: { price: 230500.25, change: 1680.50, changePercent: 0.73 }
    },
    annualChanges: {
      2024: { USD: 421.6, AUD: 624.4, CAD: 559.9, CHF: 325.4, CNY: 2731.1, EUR: 319.9, GBP: 298.8, INR: 27225.5, JPY: 48760.6 },
      2023: { USD: 212.8, AUD: 312.6, CAD: 219.9, CHF: 42.4, CNY: 1657.7, EUR: 149.7, GBP: 96.6, INR: 17134.4, JPY: 39616.6 },
      2022: { USD: -6.3, AUD: 165.5, CAD: 159.9, CHF: 18.0, CNY: 983.3, EUR: 106.0, GBP: 178.8, INR: 14070.7, JPY: 26134.4 },
      2021: { USD: -73.5, AUD: 56.2, CAD: -94.1, CHF: -36.0, CNY: -731.1, EUR: 51.9, GBP: -37.5, INR: -2096.6, JPY: 14575.5 },
      2020: { USD: 514.6, AUD: 346.6, CAD: 502.2, CHF: 252.0, CNY: 1989.9, EUR: 254.4, GBP: 319.9, INR: 36279.9, JPY: 35785.5 }
    }
  },
  silver: {
    livePrices: {
      USD: { price: 23.50, change: 0.35, changePercent: 1.51 },
      AUD: { price: 35.75, change: 0.52, changePercent: 1.48 },
      CAD: { price: 31.60, change: 0.46, changePercent: 1.48 },
      CHF: { price: 20.80, change: 0.30, changePercent: 1.46 },
      CNY: { price: 152.25, change: 2.20, changePercent: 1.47 },
      EUR: { price: 21.45, change: 0.31, changePercent: 1.47 },
      GBP: { price: 18.60, change: 0.27, changePercent: 1.47 },
      INR: { price: 1740.30, change: 25.30, changePercent: 1.48 },
      JPY: { price: 2645.50, change: 38.50, changePercent: 1.48 }
    },
    annualChanges: {
      2024: { USD: 3.6, AUD: 5.4, CAD: 4.9, CHF: 2.4, CNY: 23.1, EUR: 2.9, GBP: 2.8, INR: 272.5, JPY: 487.6 },
      2023: { USD: 2.8, AUD: 3.6, CAD: 2.9, CHF: 0.4, CNY: 17.7, EUR: 1.7, GBP: 1.6, INR: 174.4, JPY: 396.6 },
      2022: { USD: -1.3, AUD: 1.5, CAD: 1.9, CHF: 0.8, CNY: 9.3, EUR: 1.0, GBP: 1.8, INR: 140.7, JPY: 261.4 },
      2021: { USD: -3.5, AUD: 0.2, CAD: -4.1, CHF: -1.0, CNY: -7.1, EUR: 0.9, GBP: -1.5, INR: -20.6, JPY: 145.5 },
      2020: { USD: 4.6, AUD: 3.6, CAD: 5.2, CHF: 2.0, CNY: 19.9, EUR: 2.4, GBP: 3.9, INR: 362.9, JPY: 357.5 }
    }
  },
  // ... (similar structure for platinum, palladium)
};

const currencies = ['USD', 'AUD', 'CAD', 'CHF', 'CNY', 'EUR', 'GBP', 'INR', 'JPY'];
const years = [2024, 2023, 2022, 2021, 2020];

export default function MetalPriceWidget({config}: PropsI) {
  const [selectedMetal, setSelectedMetal] = useState('gold');
  const [selectedInstrument, setSelectedInstrument] = useState<Instrument>(instruments[0])
  const [showPercentage, setShowPercentage] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const {widgetWrapperRef} = useSwitchThemeModeInWidget(config.colorTheme);

  const data = sampleData[selectedMetal] ?? sampleData['gold'];
  const isSmall = config.width < 500;

  useEffect(() => {
    const timer = setInterval(() => setCurrentDateTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);


  const renderInstrumentOptions = () => {
    const groupedInstruments = {
      'Precious Metals': instruments.filter(i => i.apiType === 'precious'),
      'Base Metals': instruments.filter(i => i.apiType === 'base'),
    }
    return (
      <>
        {Object.entries(groupedInstruments).map(([group, groupInstruments]) => (
          <optgroup label={group} key={group}>
            {groupInstruments.map(instrument => (
              <option key={instrument.id} value={instrument.id}>
                {instrument.name}
              </option>
            ))}
          </optgroup>
        ))}
      </>
    )
  }

  const formatChange = (change: any, changePercent: any, isPercentage: boolean) => {
    const value = isPercentage ? changePercent : change;
    const prefix = value >= 0 ? '+' : '';
    return `${prefix}${humanizePrice(value)}${isPercentage ? '%' : ''}`;
  };

  return (
    <div ref={widgetWrapperRef} className='w-full flex justify-center'>
      <div className={`border shadow-lg rounded-lg  border-gray-200 bg-white text-gray-dark dark:bg-gray-dark dark:text-white overflow-x-hidden p-3 ${config?.isTransparent ? 'bg-opacity-0' : 'bg-white'}`}  style={{width: !config?.useContainerSize ? `${config.width}px` : '100%'}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <select 
            value={selectedInstrument.id}
            onChange={(e) => {
              const found = instruments.find(i => i.id === e.target.value)
              if (found) {
                setSelectedInstrument(found)
                setSelectedMetal(found.id); 
              }
            }}
            className="mr-2 lg:mr-2 p-2 text-[14px] rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white outline-none"
          >
            {renderInstrumentOptions()}
          </select>

          <div className="flex items-center space-x-2">
            <label htmlFor="show-change" className={`text-sm font-medium ${config.colorTheme === 'light' ?  'text-gray-light_700' : 'text-gray-light_100'}`}>Value</label>
              <Switch id="show-change" checked={showPercentage} onCheckedChange={setShowPercentage} /> 
             <label htmlFor="show-change" className={`text-sm font-medium ${config.colorTheme === 'light' ?  'text-gray-light_700' : 'text-gray-light_100'}`}>Percentage</label> 
          </div>
        </div>
        
        <div className={clsx('text-center font-semibold', !isSmall ? 'text-[18px]' : 'text-[16px]')} style={{margin: '1rem 0' }}>{selectedMetal.charAt(0).toUpperCase() + selectedMetal.slice(1)} Price Performance: {showPercentage ? '% ' : ''}Annual Change</div>
        <div className={clsx('overflow-x-auto custom-scroll-sm-white pb-1', config.colorTheme === 'dark' ? 'custom-scroll-sm-dark': 'custom-scroll-sm-white')}>
        <table className='w-[1000px] bg-white dark:bg-gray text-gray-dark dark:text-white' style={{ borderCollapse: 'collapse'}}>
          <thead>
            <tr className='border-b text-gray-dark dark:text-white'>
              <th className={clsx('text-left', isSmall ? 'p-[6px]' : 'p-[8px]')}>Year</th>
              {currencies.map(currency => (
                <th className={clsx('text-center text-[14px]', isSmall ? 'p-[6px]' : 'p-[8px]')} key={currency}>
                  {currency}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={clsx('text-gray-dark dark:text-white border-b font-bold text-[14px]', isSmall ? 'p-[6px]' : 'p-[8px]')} >Live Price</td>
              {currencies.map(currency => (
                <td key={currency} className={clsx('border-b text-center', isSmall ? 'p-[6px]' : 'p-[8px]')}>
                  <div className={clsx('font-bold', !isSmall ? 'text-[14px]' : 'text-[12px]')}>{humanizePrice(data.livePrices[currency].price)}</div>
                  <div className={clsx('', {
                         'text-green-dark dark:text-green-light': data.livePrices[currency].change > 0,
                         'text-red-dark dark:text-red-light': data.livePrices[currency].change < 0,
                         'text-gray dark:text-gray-subtext_light': data.livePrices[currency].change === 0,
                         'text-[12px]': isSmall,
                         'text-[14px]': !isSmall,
                      })}>
                    {formatChange(data.livePrices[currency].change, data.livePrices[currency].changePercent, showPercentage)}
                  </div>
                </td>
              ))}
            </tr>
            {years.map((year, index) => (
              <tr key={year} >
                <td className={clsx('text-gray-dark dark:text-white border-b text-[14px]', isSmall ? 'p-[6px]' : 'p-[8px]')}>{year}</td>
                {currencies.map(currency => {
                  const value = data.annualChanges[year][currency];
                  const percentage = (value / (data.livePrices[currency].price - value) * 100);
                  return (
                    <td key={currency} className={clsx('border-b text-center', isSmall ? 'p-[6px]' : 'p-[8px]')}>
                      <span className={clsx('', {
                         'text-green-dark dark:text-green-light': value > 0,
                         'text-red-dark dark:text-red-light': value < 0,
                         'text-gray dark:text-gray-subtext_light': value === 0,
                         'text-[12px]': isSmall,
                         'text-[14px]': !isSmall,
                      })}>
                        {formatChange(value, percentage, showPercentage)}
                      </span>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        
        <div className={clsx('flex justify-between mt-[1rem]', isSmall ? 'flex-col items-center' : 'flex-row')}>
          <LinkToKitco isDarkMode={config.colorTheme === 'dark'}/>
          <span>
            <DateNYTime fontSize='12px'/> 
          </span>
        </div>
      
      </div>
    </div>
  );
}
