import ApiService from 'api/apiService';
import clsx from 'clsx';
import { DateNYTime } from '../components/Date/DateNYTime';
import LinkToKitco from '../components/Link/LinkToKitco';
import { generatePriceChangeColorsWithTheme, humanizePrice } from '../components/utils';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import React, { useState, useEffect } from 'react';

interface WidgetProps {
  config: WidgetConfig; 
}

interface AssetsI {
  symbol: string; 
  name: string; 
  icon: string; 
  color: string; 
  price: number; 
  change: number; 
  changePercent: number; 
  darkColor: string;
}

interface ItemProps {
  asset: AssetsI; 
  theme: 'light' | 'dark';  
}

const assetsDefault = [
  { symbol: 'AU', name: 'Gold', icon: 'Au', color: '#FFD700', darkColor: '#aea675' },
  { symbol: 'AG', name: 'Silver', icon: 'Ag', color: '#C0C0C0', darkColor: '#8f8e86' },
  { symbol: 'PT', name: 'Platinum', icon: 'Pt', color: '#E5E4E2', darkColor: '#2c7d26' },
  { symbol: 'PD', name: 'Palladium', icon: 'Pd', color: '#CED0DD', darkColor: '#23625f' },
  { symbol: 'CL', name: 'Crude Oil', icon: 'Oil', color: '#000000', darkColor: '#a18f2a' },
  { symbol: 'BTC', name: 'Bitcoin', icon: '₿', color: '#F7931A', darkColor: '#b82245' },
  { symbol: 'ETH', name: 'Ethereum', icon: 'Ξ', color: '#627EEA', darkColor: '#186db7' },
];

const TickerItem = ({ asset, theme }: ItemProps) => {

  const formatChange = (change: number, percent: number) => {
    const sign = change >= 0 ? '+' : '-'
    return `${sign}$${Math.abs(change).toFixed(2)} (${sign}${Math.abs(percent).toFixed(2)}%)`
  }
  return (
    <div className='inline-flex items-center px-4 py-2 border-r border-gray-200 last:border-r-0 whitespace-nowrap'>
      <div className='flex items-center font-bold mr-2'>
        <div
          className={'w-6 h-6 mr-2 rounded-full flex items-center justify-center text-white text-xs font-bold'}
          style={{ backgroundColor: theme === 'light' ? asset.color : asset.darkColor }}
        >
          {asset?.icon}
        </div>
        {asset?.name}
      </div>
      <span className='font-bold mr-2'>${humanizePrice(asset?.price)}</span>
      <span className={clsx(`text-sm`, generatePriceChangeColorsWithTheme(asset?.change))}>
        {formatChange(asset?.change, asset?.changePercent)}
      </span>
    </div>
  );
};

const KitcoFinancialTicker = ({config}: WidgetProps) => {
  const [assets, setAssets] = useState<AssetsI[]>([]);

  const {widgetWrapperRef} = useSwitchThemeModeInWidget(config.colorTheme);

  const getData = async () => {
    try {
      const data = ['AU', 'AG', 'PT', 'PD', 'CL', 'BTC', 'ETH', 'RH']; 
      const newAssets: any = {}; 
      data.forEach(item => {
        newAssets[item] = {price: '', change: '', changePercent: ''}; 
      }); 

      const PMResponse = await ApiService.getPMBaseData(); 
      const energyResponse = await ApiService.getOilBaseData(); 
      const cryptoResponse = await ApiService.getCrypto('BTC,ETH'); 

      PMResponse.PreciousMetals.PM.forEach(item => {
        newAssets[item.Symbol].price = item.Bid; 
        newAssets[item.Symbol].change = item.Change; 
        newAssets[item.Symbol].changePercent = item.ChangePercentage; 
        
      }); 

      const oilData = energyResponse.Values.Value; 
      newAssets[oilData.Symbol].price = oilData.Price; 
      newAssets[oilData.Symbol].change = oilData.Change; 
      newAssets[oilData.Symbol].changePercent = oilData.ChangePercentage; 

      cryptoResponse?.results?.data_set?.forEach(item => {
        newAssets[item.symbol].price = item.rates[0].high; 
        newAssets[item.symbol].change = item.rates[0].change;
        newAssets[item.symbol].changePercent = item.rates[0].change_percent;
      });


      const result = assetsDefault.map(item => ({...item, ...newAssets[item.symbol]})); 
      setAssets(result);
      
    } catch (error) {
      console.log('error', error); 
    }
  }
  

  useEffect(() => {
    getData(); 
    const interval = setInterval(getData, 100000)
    return () => clearInterval(interval)
  }, []); 

  return (
    <div ref={widgetWrapperRef} className={'font-sans overflow-x-auto'} style={{ width: !config?.useContainerSize ? `${config.width}px` : '100%' }}>
      <div className={`border border-gray-200 bg-white text-gray-dark dark:bg-gray-dark dark:text-white overflow-x-hidden ${assets.length ? 'w-max' : 'w-full'}  ${config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} >
        {assets.length ? <div className='flex animate-ticker'>
          {assets.length && assets.map((asset, idx) => (
            <TickerItem key={asset.symbol} asset={asset} theme={config.colorTheme}/>
          ))}
        </div> : null}
        <div className='flex relative justify-center items-center py-2 px-4 bg-gray-light_100 dark:bg-gray-light_700 border-t border-gray-200 text-sm font-bold w-full'>
          <div className='absolute left-2 pt-1 '>
             <DateNYTime fontSize='12px'/>
          </div>
          
          <LinkToKitco isDarkMode={config.colorTheme === 'dark'}/>
        </div>
      </div>
    </div>
  );
};

export default KitcoFinancialTicker;
