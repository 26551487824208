import { instruments } from '../components/constants';
import { DateNYTime } from '../components/Date/DateNYTime';
import LinkToKitco from '../components/Link/LinkToKitco';
import { Instrument } from '../components/types';
import { humanizePrice } from '../components/utils';
import { Switch } from '../@/components/ui/switch';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useGoogleAnaliticsHandler } from '../hooks/useGoogleAnaliticsHandler';

interface PropsI {
  config: WidgetConfig;
}

/* const sampleData: any = {
  gold: {
    livePrices: {
      USD: { price: 2050.30, change: 15.20, changePercent: 0.75 },
      AUD: { price: 3125.45, change: 22.80, changePercent: 0.73 },
      CAD: { price: 2765.20, change: 20.15, changePercent: 0.73 },
      CHF: { price: 1800.15, change: 12.50, changePercent: 0.70 },
      CNY: { price: 13250.75, change: 95.60, changePercent: 0.73 },
      EUR: { price: 1875.60, change: 13.80, changePercent: 0.74 },
      GBP: { price: 1620.40, change: 11.90, changePercent: 0.74 },
      INR: { price: 152000.50, change: 1120.30, changePercent: 0.74 },
      JPY: { price: 230500.25, change: 1680.50, changePercent: 0.73 }
    },
    annualChanges: {
      2025: { USD: 421.6, AUD: 624.4, CAD: 559.9, CHF: 325.4, CNY: 2731.1, EUR: 319.9, GBP: 298.8, INR: 27225.5, JPY: 48760.6 },
      2024: { USD: 212.8, AUD: 312.6, CAD: 219.9, CHF: 42.4, CNY: 1657.7, EUR: 149.7, GBP: 96.6, INR: 17134.4, JPY: 39616.6 },
      2023: { USD: -6.3, AUD: 165.5, CAD: 159.9, CHF: 18.0, CNY: 983.3, EUR: 106.0, GBP: 178.8, INR: 14070.7, JPY: 26134.4 },
      2022: { USD: -73.5, AUD: 56.2, CAD: -94.1, CHF: -36.0, CNY: -731.1, EUR: 51.9, GBP: -37.5, INR: -2096.6, JPY: 14575.5 },
      2021: { USD: 514.6, AUD: 346.6, CAD: 502.2, CHF: 252.0, CNY: 1989.9, EUR: 254.4, GBP: 319.9, INR: 36279.9, JPY: 35785.5 }
    }
  },
  silver: {
    livePrices: {
      USD: { price: 23.50, change: 0.35, changePercent: 1.51 },
      AUD: { price: 35.75, change: 0.52, changePercent: 1.48 },
      CAD: { price: 31.60, change: 0.46, changePercent: 1.48 },
      CHF: { price: 20.80, change: 0.30, changePercent: 1.46 },
      CNY: { price: 152.25, change: 2.20, changePercent: 1.47 },
      EUR: { price: 21.45, change: 0.31, changePercent: 1.47 },
      GBP: { price: 18.60, change: 0.27, changePercent: 1.47 },
      INR: { price: 1740.30, change: 25.30, changePercent: 1.48 },
      JPY: { price: 2645.50, change: 38.50, changePercent: 1.48 }
    },
    annualChanges: {
      2025: { USD: 3.6, AUD: 5.4, CAD: 4.9, CHF: 2.4, CNY: 23.1, EUR: 2.9, GBP: 2.8, INR: 272.5, JPY: 487.6 },
      2024: { USD: 2.8, AUD: 3.6, CAD: 2.9, CHF: 0.4, CNY: 17.7, EUR: 1.7, GBP: 1.6, INR: 174.4, JPY: 396.6 },
      2023: { USD: -1.3, AUD: 1.5, CAD: 1.9, CHF: 0.8, CNY: 9.3, EUR: 1.0, GBP: 1.8, INR: 140.7, JPY: 261.4 },
      2022: { USD: -3.5, AUD: 0.2, CAD: -4.1, CHF: -1.0, CNY: -7.1, EUR: 0.9, GBP: -1.5, INR: -20.6, JPY: 145.5 },
      2021: { USD: 4.6, AUD: 3.6, CAD: 5.2, CHF: 2.0, CNY: 19.9, EUR: 2.4, GBP: 3.9, INR: 362.9, JPY: 357.5 }
    }
  },
  // ... (similar structure for platinum, palladium)
}; */

const sampleData: any = {
  gold: {
    livePrices: {
      USD: { price: 2050.30, change: 15.20, changePercent: 0.75 },
      AUD: { price: 3125.45, change: 22.80, changePercent: 0.73 },
      BRL: { price: 10500.75, change: 80.60, changePercent: 0.77 },
      CAD: { price: 2765.20, change: 20.15, changePercent: 0.73 },
      CHF: { price: 1800.15, change: 12.50, changePercent: 0.70 },
      CNY: { price: 13250.75, change: 95.60, changePercent: 0.73 },
      EUR: { price: 1875.60, change: 13.80, changePercent: 0.74 },
      GBP: { price: 1620.40, change: 11.90, changePercent: 0.74 },
      HKD: { price: 15900.50, change: 120.30, changePercent: 0.76 },
      INR: { price: 152000.50, change: 1120.30, changePercent: 0.74 },
      JPY: { price: 230500.25, change: 1680.50, changePercent: 0.73 },
      MXN: { price: 41000.75, change: 320.60, changePercent: 0.78 },
      RUB: { price: 152000.50, change: 1120.30, changePercent: 0.74 },
      ZAR: { price: 30500.25, change: 250.50, changePercent: 0.82 }
    },
    annualChanges: {
      2025: { USD: 421.6, AUD: 624.4, BRL: 3100.7, CAD: 559.9, CHF: 325.4, CNY: 2731.1, EUR: 319.9, GBP: 298.8, HKD: 3200.5, INR: 27225.5, JPY: 48760.6, MXN: 4100.7, RUB: 27225.5, ZAR: 3050.2 },
      2024: { USD: 212.8, AUD: 312.6, BRL: 1600.3, CAD: 219.9, CHF: 42.4, CNY: 1657.7, EUR: 149.7, GBP: 96.6, HKD: 1600.3, INR: 17134.4, JPY: 39616.6, MXN: 2100.3, RUB: 17134.4, ZAR: 2050.1 },
      2023: { USD: -6.3, AUD: 165.5, BRL: 800.2, CAD: 159.9, CHF: 18.0, CNY: 983.3, EUR: 106.0, GBP: 178.8, HKD: 800.2, INR: 14070.7, JPY: 26134.4, MXN: 1100.2, RUB: 14070.7, ZAR: 1050.1 },
      2022: { USD: -73.5, AUD: 56.2, BRL: -400.1, CAD: -94.1, CHF: -36.0, CNY: -731.1, EUR: 51.9, GBP: -37.5, HKD: -400.1, INR: -2096.6, JPY: 14575.5, MXN: -550.1, RUB: -2096.6, ZAR: -525.0 },
      2021: { USD: 514.6, AUD: 346.6, BRL: 2000.3, CAD: 502.2, CHF: 252.0, CNY: 1989.9, EUR: 254.4, GBP: 319.9, HKD: 2000.3, INR: 36279.9, JPY: 35785.5, MXN: 2100.4, RUB: 36279.9, ZAR: 2050.2 }
    }
  },
  silver: {
    livePrices: {
      USD: { price: 23.50, change: 0.35, changePercent: 1.51 },
      AUD: { price: 35.75, change: 0.52, changePercent: 1.48 },
      BRL: { price: 120.00, change: 1.80, changePercent: 1.52 },
      CAD: { price: 31.60, change: 0.46, changePercent: 1.48 },
      CHF: { price: 20.80, change: 0.30, changePercent: 1.46 },
      CNY: { price: 152.25, change: 2.20, changePercent: 1.47 },
      EUR: { price: 21.45, change: 0.31, changePercent: 1.47 },
      GBP: { price: 18.60, change: 0.27, changePercent: 1.47 },
      HKD: { price: 159.00, change: 2.30, changePercent: 1.48 },
      INR: { price: 1740.30, change: 25.30, changePercent: 1.48 },
      JPY: { price: 2645.50, change: 38.50, changePercent: 1.48 },
      MXN: { price: 410.00, change: 6.00, changePercent: 1.47 },
      RUB: { price: 1740.30, change: 25.30, changePercent: 1.48 },
      ZAR: { price: 305.00, change: 4.50, changePercent: 1.48 }
    },
    annualChanges: {
      2025: { USD: 3.6, AUD: 5.4, BRL: 15.0, CAD: 4.9, CHF: 2.4, CNY: 23.1, EUR: 2.9, GBP: 2.8, HKD: 3.2, INR: 272.5, JPY: 487.6, MXN: 4.1, RUB: 272.5, ZAR: 3.0 },
      2024: { USD: 2.8, AUD: 3.6, BRL: 7.5, CAD: 2.9, CHF: 0.4, CNY: 17.7, EUR: 1.7, GBP: 1.6, HKD: 1.6, INR: 174.4, JPY: 396.6, MXN: 2.1, RUB: 174.4, ZAR: 2.0 },
      2023: { USD: -1.3, AUD: 1.5, BRL: 3.8, CAD: 1.9, CHF: 0.8, CNY: 9.3, EUR: 1.0, GBP: 1.8, HKD: 0.8, INR: 140.7, JPY: 261.4, MXN: 1.1, RUB: 140.7, ZAR: 1.0 },
      2022: { USD: -3.5, AUD: 0.2, BRL: -1.9, CAD: -4.1, CHF: -1.0, CNY: -7.1, EUR: 0.9, GBP: -1.5, HKD: -0.4, INR: -20.6, JPY: 145.5, MXN: -0.5, RUB: -20.6, ZAR: -0.5 },
      2021: { USD: 4.6, AUD: 3.6, BRL: 10.0, CAD: 5.2, CHF: 2.0, CNY: 19.9, EUR: 2.4, GBP: 3.9, HKD: 2.0, INR: 362.9, JPY: 357.5, MXN: 2.1, RUB: 362.9, ZAR: 2.0 }
    }
  },
  // ... (similar structure for platinum, palladium)
};

const currencies = ['USD', 'AUD', 'BRL', 'CAD', 'CHF', 'CNY', 'EUR', 'GBP', 'HKD', 'INR', 'JPY', 'MXN', 'RUB', 'ZAR'];

export default function MetalPriceWidget({config}: PropsI) {
  const [selectedMetal, setSelectedMetal] = useState('gold');
  const [selectedInstrument, setSelectedInstrument] = useState<Instrument>(instruments[0])
  const [showPercentage, setShowPercentage] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [years, setYears] = useState<number[]>([]);

  useGoogleAnaliticsHandler('Precious_Metals_Yearly_Performance');
  const {widgetWrapperRef} = useSwitchThemeModeInWidget(config.colorTheme);

  const data = sampleData[selectedMetal] ?? sampleData['gold'];
  const isSmall = config.width < 550;

  const setDefaultYears = () => {
    const currentYear = new Date().getFullYear();
    const dynamicYears = Array.from({ length: 5 }, (_, i) => currentYear - i);
    setYears(dynamicYears);
  } 

  useEffect(() => {
    setDefaultYears();
    const timer = setInterval(() => setCurrentDateTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const renderInstrumentOptions = () => {
    const groupedInstruments = {
      'Precious Metals': instruments.filter(i => i.apiType === 'precious'),
      'Base Metals': instruments.filter(i => i.apiType === 'base'),
    }
    return (
      <>
        {Object.entries(groupedInstruments).map(([group, groupInstruments]) => (
          <optgroup label={group} key={group}>
            {groupInstruments.map(instrument => (
              <option key={instrument.id} value={instrument.id}>
                {instrument.name}
              </option>
            ))}
          </optgroup>
        ))}
      </>
    )
  }

  const formatChange = (change: any, changePercent: any, isPercentage: boolean) => {
    const value = isPercentage ? changePercent : change;
    const prefix = value >= 0 ? '+' : '';
    return `${prefix}${humanizePrice(value)}${isPercentage ? '%' : ''}`;
  };

  return (
    <div ref={widgetWrapperRef} className='w-full flex justify-center'>
      <div className={`border shadow-lg rounded-lg  border-gray-200 bg-white text-gray-dark dark:bg-gray-dark dark:text-white overflow-x-hidden px-5 pt-3 ${config?.isTransparent ? 'bg-opacity-0' : 'bg-white'}`}  style={{width: !config?.useContainerSize ? `${config.width}px` : '100%'}}>

      <div className={clsx('text-center font-[500] w-full text-[20px] pb-2', !isSmall ? 'hidden' : 'block')}>
          <div>{selectedMetal.charAt(0).toUpperCase() + selectedMetal.slice(1)} Annual Performance</div>
          <span>
            <DateNYTime fontSize='12px'/> 
          </span>
      </div>

        <div className='flex items-center justify-between w-full' style={{ marginBottom: '1rem' }}>
          <div className={isSmall ? 'w-1/2' : 'w-1/4'}>
            <select 
              value={selectedInstrument.id}
              onChange={(e) => {
                const found = instruments.find(i => i.id === e.target.value)
                if (found) {
                  setSelectedInstrument(found)
                  setSelectedMetal(found.id); 
                }
              }}
              className="p-2 text-[14px] rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white outline-none"
            >
              {renderInstrumentOptions()}
            </select>
          </div>


          <div className={clsx('text-center font-[500] w-1/2', !isSmall ? 'text-[20px]' : 'text-[16px] hidden')}>
              <div>{selectedMetal.charAt(0).toUpperCase() + selectedMetal.slice(1)} Annual Performance</div>
              <span>
                <DateNYTime fontSize='12px'/> 
              </span>
          </div>

          <div className={clsx("flex items-center space-x-1", isSmall ? 'w-1/2' : 'w-1/4')}>
            <label htmlFor="show-change" className={`text-[12px] font-medium ${config.colorTheme === 'light' ?  'text-gray-light_700' : 'text-gray-light_100'}`}>Value</label>
              <Switch id="show-change" checked={showPercentage} onCheckedChange={setShowPercentage} /> 
             <label htmlFor="show-change" className={`text-[12px] font-medium ${config.colorTheme === 'light' ?  'text-gray-light_700' : 'text-gray-light_100'}`}>Percentage</label> 
          </div>
        </div>
        

        <div className={clsx('pb-1', config.colorTheme === 'dark' ? 'custom-scroll-sm-dark': 'custom-scroll-sm-white', isSmall ? 'overflow-x-scroll' : 'overflow-x-auto')}>
        <table className='w-full bg-white dark:bg-gray text-gray-dark dark:text-white' style={{ borderCollapse: 'collapse'}}>
          <thead>
            <tr className='border-b text-gray-dark dark:text-white'>
            <th className={clsx('text-left', isSmall ? 'p-[6px]  sticky left-0 bg-white dark:bg-gray' : 'p-[6px]')}></th>
            <th className={clsx('text-center font-normal', isSmall ? 'p-[6px] text-[12px]' : 'text-[14px] p-[6px]')}>Live Price</th>
              {years.map(year => (
                <th className={clsx('text-center font-normal', isSmall ? 'p-[6px] text-[16px]' : 'p-[6px] text-[18px]')} key={year}>
                  {year}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currencies.map((currency, index) => (
              <tr key={currency} >
                 <td className={clsx('text-gray-dark dark:text-white border-b text-[12px] min-w-[70px]', isSmall ? 'bg-white dark:bg-gray sticky left-0 p-[6px]' : 'p-[8px]')}>
                    <div className="flex items-center space-x-1">
                      <img 
                        // src={`https://flagcdn.com/w20/${currencyToCountryCode[currency.symbol].toLowerCase()}.png`}
                        src={`https://www.kitco.com/flags/${currency}.png`}
                        alt={`${currency} flag`}
                        className="text-[10px] w-[21px] h-[14px]"
                      />
                      <span className={clsx('text-left font-semibold text-gray-dark dark:text-gray-subtext_light', isSmall ? 'text-[11px]' : 'text-[12px]')}>{currency}</span>
                    </div>
                 </td>

                <td className={clsx('border-b text-center', isSmall ? 'p-[6px]' : 'p-[6px]')}>
                  <div className={clsx('font-bold', !isSmall ? 'text-[12px]' : 'text-[12px]')}>{humanizePrice(data.livePrices[currency].price)}</div>
                  <div className={clsx(!isSmall ? 'text-[12px]' : 'text-[12px]', {
                         'text-green-dark dark:text-green-light': data.livePrices[currency].change > 0,
                         'text-red-dark dark:text-red-light': data.livePrices[currency].change < 0,
                         'text-gray dark:text-gray-subtext_light': data.livePrices[currency].change === 0,
                      })}>
                    {formatChange(data.livePrices[currency].change, data.livePrices[currency].changePercent, showPercentage)}
                  </div>
                </td>

                {years.map(year => {
                  const value = data.annualChanges[year][currency];
                  const percentage = (value / (data.livePrices[currency].price - value) * 100);
                  return (
                    <td key={year} className={clsx('border-b text-center', isSmall ? 'p-[6px]' : 'p-[6px]')}>
                      <span className={clsx(!isSmall ? 'text-[12px]' : 'text-[12px]', {
                         'text-green-dark dark:text-green-light': value > 0,
                         'text-red-dark dark:text-red-light': value < 0,
                         'text-gray dark:text-gray-subtext_light': value === 0,
                      })}>
                        {formatChange(value, percentage, showPercentage)}
                      </span>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        
        <div className={clsx('flex justify-end pt-3 pb-4')}>
          <LinkToKitco isDarkMode={config.colorTheme === 'dark'} widgetName='Precious_Metals_Yearly_Performance'/>
        </div>
      
      </div>
    </div>
  );
}
