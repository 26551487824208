import React from 'react'
import { LucideProps } from 'lucide-react'

interface BubbleProps {
  label: string
  change: number
  changePercent: number
  icon: React.ElementType<LucideProps>
  size: number
  x: number
  y: number
}

const Bubble: React.FC<BubbleProps> = ({ label, change, changePercent, icon: Icon, size, x, y }) => {
  const isZero = change === 0
  const isPositive = change > 0
  const bgColor = isZero ? 'bg-gray-400' : (isPositive ? 'bg-green-500' : 'bg-red-500')
  const textColor = 'text-white'

  return (
    <div
      className={`bubble ${bgColor} ${textColor} rounded-full flex flex-col items-center justify-center shadow-lg absolute transition-all duration-300 ease-in-out`}
      style={{ width: `${size}px`, height: `${size}px`, left: `${x}%`, top: `${y}%`, transform: 'translate(-50%, -50%)' }}
    >
      <Icon size={size * 0.2} className="mb-1" />
      <div className="text-lg font-bold">{label}</div>
      <div className="text-sm">{change.toFixed(2)}</div>
      <div className="text-xs">{changePercent.toFixed(2)}%</div>
    </div>
  )
}

export default Bubble
