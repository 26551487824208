import React from 'react'
import { Instrument } from './types'

interface ChartData {
    [key: string]: {
        [key: string]: string
    }
}

interface ChartVisualizationProps {
    instrument: Instrument
    period: {
        id: string
        name: string
    }
    isDarkMode: boolean
}

const ChartVisualization: React.FC<ChartVisualizationProps> = ({ instrument, period, isDarkMode }) => {
    const chartImages: ChartData = {
        gold: {
            realTime: 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-24h-kgx-usd.gif',
            '30days': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-30d-kgx-usd.gif',
            '60days': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-60d-kgx-usd.gif',
            '6months': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-180d-kgx-usd.gif',
            '1year': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-365d-kgx-usd.gif',
            '5years': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-1825d-kgx-usd.gif',
            '10years': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-3650d-kgx-usd.gif',
        },
        silver: {
            realTime: 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-ag-24h-kgx-usd.gif',
            '30days': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-ag-30d-kgx-usd.gif',
            '60days': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-ag-60d-kgx-usd.gif',
            '6months': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-ag-180d-kgx-usd.gif',
            '1year': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-ag-365d-kgx-usd.gif',
            '5years': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-ag-1825d-kgx-usd.gif',
            '10years': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-ag-3650d-kgx-usd.gif',
        },
        platinum: {
            realTime: 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pt-24h-kgx-usd.gif',
            '30days': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pt-30d-kgx-usd.gif',
            '60days': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pt-60d-kgx-usd.gif',
            '6months': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pt-180d-kgx-usd.gif',
            '1year': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pt-365d-kgx-usd.gif',
            '5years': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pt-1825d-kgx-usd.gif',
            '10years': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pt-3650d-kgx-usd.gif',

        },
        palladium: {
            realTime: 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pd-24h-kgx-usd.gif',
            '30days': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pd-30d-kgx-usd.gif',
            '60days': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pd-60d-kgx-usd.gif',
            '6months': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pd-180d-kgx-usd.gif',
            '1year': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pd-365d-kgx-usd.gif',
            '5years': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pd-1825d-kgx-usd.gif',
            '10years': 'https://proxy.kitco.com/kcast//live/exch/438_235/2a-pd-3650d-kgx-usd.gif',

        }
    }

    const availableCharts = ['gold', 'silver', 'platinum', 'palladium']

    if (!availableCharts.includes(instrument.id)) {
        return (
          <div className="w-full h-64 bg-white dark:bg-gray-700 rounded-lg overflow-hidden flex items-center justify-center">
            <p className="text-lg font-semibold text-gray-600 dark:text-gray-300">
              {instrument.name} charts coming soon!
            </p>
          </div>
        )
      }

    const imageUrl = chartImages[instrument.id][period.id]

    return (
        <div className="w-full h-64 bg-white rounded-lg overflow-hidden">
            <img
                src={imageUrl}
                alt={`${instrument.name} - ${period.name}`}
                className="w-full h-full object-fit"
            />
        </div>
    )
}

export default ChartVisualization
