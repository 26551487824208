import clsx from "clsx";
import React from "react";

interface DateNYTimeProps {
  fontSize?: string;
  isLightMode?: boolean;
  isDarkMode?: boolean;
}

export const DateNYTimeShort = ({fontSize, isLightMode, isDarkMode}: DateNYTimeProps) => {
  const currentDateTime = new Date().toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'America/New_York',
  });

  return (
    <div className={clsx('mb-[2px]', {
      'text-gray-subtext_light': isLightMode, 
      'text-gray-subtext_dark': isDarkMode,
      'text-gray-subtext_dark dark:text-gray-subtext_light': (!isLightMode && !isDarkMode),
    })} style={{ fontSize: fontSize ?? '10px'}}>
      {currentDateTime} NY
    </div>
  );
};