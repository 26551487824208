import FinancialWidgetPage from "pages/FinancialWidgetPage";
import { Path } from "./constants";
import { Navigate } from "react-router-dom";
import KGXMiniWidgetPage from "pages/KGXMiniWidgetPage";
import ComodityWidgetPage from "pages/ComodityWidgetPage";
import FinancialTickerPage from "pages/FinancialTickerPage";
import CrossRatesPage from "pages/CrossReatesPage";
import GoldChartPage from "pages/ThreeDaysChartsPage";
import NewsWidgetPage from "pages/NewsWidgetPage";
import PreciousMetalsPage from "pages/PreciousMetalsPage";
import MetalBidWidgetPage from "pages/MetalBidWidgetPage";
import MiniKgxBidWidgetPage from "pages/MiniKgxBidWidget";
import GoldBidPage from "pages/GoldBidPage";
import MetalPricePage from "pages/MetalPrice";
import FinalKitcoPriceCalculatorPage from "pages/FinalKitcoPriceCalculatorPage";
import TutorialPage from "pages/Tutorial";
import Home from "pages/Home";
import WidgetsPage from "pages/WidgetsPage";
import KitcoBrandedMetalPerformancePage from "pages/KitcoBrandedMetalPerformancePage";
import CompactGoldPriceWidgetPage from "pages/CompactPriceWidgetPage";
import SpotPriceWidgetPage from "pages/SpotPriceWidgetPage";
import LondonFixWidgetPage from "pages/LondonFixWidgetPage";
import WidgetsDocsPage from "pages/WidgetsDocs";
import ContactUsPage from "pages/ContactUsPage";
import TutorialCalculatorPage from "pages/TutorialCalculator";
import TutorialBrandedPage from "pages/TutorialBranded";
import NYChartWidgetPage from "pages/NYChartWidgetPage";
import BaseMetalsChartsPage from "pages/BaseMetalsChartsPage";


const routes: any = [
/*     {
      path: '/',
      element: <Navigate to="/widgets/kgx" />
    }, */
    {
      path: '/',
      element: <Home/>
    },
    {
      path: Path.WIDGETS,
      element: <WidgetsPage/>,
      children: [
        {
          path: '',
          element:  <WidgetsDocsPage/>
        }, 
        {
          path: Path.KGX,
          element: <FinancialWidgetPage/>
        }, 
        {
          path: Path.MINI_KGX,
          element: <KGXMiniWidgetPage/>
        }, 
        {
          path: Path.COMODITY,
          element: <ComodityWidgetPage/>
        }, 
        {
          path: Path.COMODITY_KGX,
          element: <ComodityWidgetPage/>
        }, 
        {
          path: Path.FINANCIAL_TICKER,
          element: <FinancialTickerPage/>
        }, 
        {
          path: Path.CROSS_RATES,
          element: <CrossRatesPage/>
        }, 
        {
          path: Path.GOLD_CHART,
          element: <GoldChartPage/>
        }, 
        {
          path: Path.NEWS,
          element: <NewsWidgetPage/>
        }, 
        {
          path: Path.PRECIOUS_METALS,
          element: <PreciousMetalsPage/>
        }, 
        {
          path: Path.METAL_BID,
          element: <MetalBidWidgetPage/>
        }, 
        {
          path: Path.MINI_KGX_BID,
          element: <MiniKgxBidWidgetPage/>
        }, 
        {
          path: Path.GOLD_BID,
          element: <GoldBidPage/>
        }, 
        {
          path: Path.METAL_PRICE_PERFORMANCE_TABLE,
          element: <MetalPricePage/>
        }, 
        {
          path: Path.METAL_PRICE_PERFORMANCE,
          element: <MetalPricePage/>
        }, 
        {
          path: Path.PRICE_CALCULATOR,
          element: <FinalKitcoPriceCalculatorPage/>
        }, 
        {
          path: Path.BRANDED_METAL_PERFORMANCE,
          element: <KitcoBrandedMetalPerformancePage/>
        }, 
        {
          path: Path.SMALL_GOLD_PRICE,
          element: <CompactGoldPriceWidgetPage/>
        }, 
        {
          path: Path.SPOT_PRICE,
          element: <SpotPriceWidgetPage/>
        }, 
        {
          path: Path.LONDON_FIX,
          element: <LondonFixWidgetPage/>
        }, 
        {
          path: Path.NY_CHART,
          element: <NYChartWidgetPage/>
        },
        {
          path: Path.BASE_METALS, 
          element: <BaseMetalsChartsPage/>
        }
      ]
    },
    {
      path: Path.CONTACT_US,
      element: <ContactUsPage/>
    }, 
    {
      path: Path.TUTORIAL,
      element: <TutorialPage/>
    },
    {
      path: Path.TUTORIAL_CALCULATOR,
      element: <TutorialCalculatorPage/>
    },
    {
      path: Path.TUTORIAL_BRANDED_PERFORMANCE,
      element: <TutorialBrandedPage/>
    }
];

export default routes; 