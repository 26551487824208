import clsx from 'clsx';
import { CalculatorIcon, ChartCandlestick, ChartCandlestickIcon, ChartSpline, ClipboardList, Newspaper, SchoolIcon } from 'lucide-react';
import kgi from '../assets/icons/KGI.svg';
import precious_metals from '../assets/icons/Precious Metals.svg';
import tables from '../assets/icons/Tables.svg';
import tickers from '../assets/icons/Tickers.svg';
import React from 'react';

function WidgetsDocsPage() {
  const listCards = [
    {id: 1, icon: <img src={kgi} alt='' className='h-[40px] w-[40px]'/>, title: 'Kitco Global Index', url: '#/widgets/kgx', text: 'Correlate the relation between the USD Index fluctuations and its impact on the commodities price.'},
    {id: 2, icon: <img src={precious_metals} alt='' className='h-[40px] w-[40px]'/>, title: 'Precious Metals', url: '#/widgets/small_gold_price', text: 'Get the latest precious metals spot prices and London Fix.'},
/*     {id: 3, icon: <img src={tables} alt='' className='h-[40px] w-[40px]'/>, title: 'Tables', url: '#/widgets/comodity', text: 'Commodities and FX data in user friendly table formats.'}, */
    {id: 4, icon: <ChartCandlestickIcon height={40} width={40}/>, title: 'Charts', url: '#/widgets/gold_chart', text: 'Get visual with our classic commodities charts.'},
    {id: 5, icon: <ChartSpline height={40} width={40}/>, title: 'Historical Data', url:'#/widgets/branded_metal_performance', text: 'Follow the trend with our historical performance data.'},
    {id: 6, icon: <Newspaper height={40} width={40}/>, title: 'News', url: '#/widgets/news', text: 'Keep informed about the latest market news.'},
    {id: 7, icon: <CalculatorIcon height={40} width={40}/>, title: 'Calculator', url: '/#/widgets/price_calculator', text: 'Precious metals price calculator, in 14 currencies and 3 units of measure.'},
    {id: 8, icon: <img src={tickers} alt='' className='h-[40px] w-[40px]'/>, title: 'Tickers', url: '/#/widgets/financial_ticker', text: 'A convenient ticker format to top your market related pages.'},
    {id: 9, icon: <SchoolIcon height={40} width={40}/>, title: 'Tutorial', url: '#/tutorial/kgx', text: 'With you every step of the way. Check out these great tutorials for widgets installation.'},
  ]; 
  return (
    <div className='pt-[40px] pb-[40px] w-full flex flex-col items-start lg:px-[40px]'>
      <div className='text-[30px] font-bold pb-4'>Widgets</div>
      <div className='text-[16px] lg:w-2/3'>
        Welcome to the Kitko Widgets collection, where financial magic meets the simplicity of copy-paste. These powerful tools will supercharge your website with real-time financial data and stunning charts. Say goodbye to dull numbers and hello to dynamic visualizations that'll make your visitors go "Wow!"
      </div>

      <div className='text-[20px] font-bold pt-4'>Ready to explore?</div>
      <div className='text-[16px]'>Just click on a category below to get started.</div>

      <div className='w-full grid grid-cols-1 lg:grid-cols-3 gap-4 pt-6'>
         {listCards.map((item, idx) => <div key={item.id} className={'relative border border-gray-300 pl-6 py-6 rounded-lg h-[250px]'}>
               <div>{item.icon}</div>
               <div className='pt-4 text-[16px] font-bold'>{item.title}</div>  
               <div className='text-[14px] mb-4'>{item.text}</div>
               <a className='absolute bottom-4 px-2 py-2 bg-gray-300 text-[14px] rounded-lg' href={item.url}>See widgets</a>
         </div>)}   
      </div>
    </div>
  );
}

export default WidgetsDocsPage;