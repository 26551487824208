import React from 'react';

interface LinkToKitcoProps {
  isDarkMode: boolean;
  isShowLogo?: boolean;
}

export default function LinkToKitco({ isDarkMode, isShowLogo = true }: LinkToKitcoProps) {
  return (
    <div className='flex items-center justify-center text-[14px] '>
        {isShowLogo && <img src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/ShortLogo.svg'} alt='logg' className='h-[20px] pr-1'/>}
        <a
            href='https://www.kitco.com'
            target='_blank'
            rel='noopener noreferrer'
            className={`${
            isDarkMode
                ? 'text-white'
                : 'text-gray-dark'
            }`}
        >
            Powered by <span className={isDarkMode ? 'text-blue-light' : 'text-blue-dark'}>kitco.com</span>
        </a>
    </div>

  );
}
