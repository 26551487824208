import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardFooter } from '../@/components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../@/components/ui/tabs';
import LinkToKitco from '../components/Link/LinkToKitco';
import { shortSymbolFormatted } from '../common/constants';
import ApiService from 'api/apiService';
import clsx from 'clsx';
import { generatePriceChangeColorsWithTheme, humanizePrice } from '../components/utils';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import { useGoogleAnaliticsHandler } from '../hooks/useGoogleAnaliticsHandler';

interface PropsI {
  config: WidgetConfig;
}

const metals = [
  { name: 'Gold', symbol: 'AU', color: '#FF0000' },
  { name: 'Silver', symbol: 'AG', color: '#C0C0C0' },
  { name: 'Platinum', symbol: 'PT', color: '#E5E4E2' },
  { name: 'Palladium', symbol: 'PD', color: '#CED0DD' },
  { name: 'Rhodium', symbol: 'RH', color: '#18288c' }
];

const timeframes = ['24H', '30D', '60D', '6M', '1Y', '5Y', '10Y'];
const formatTimeframe: any = {
  '24H': '24h',
  '30D': '30d',
  '60D': '60d',
  '6M': '180d',
  '1Y': '365d',
  '5Y': '1825d',
  '10Y': '3650d'
}

const PreciousMetalsWidget = ({config}: PropsI) => {
  const [selectedMetal, setSelectedMetal] = useState('AU');
  const [selectedTimeframe, setSelectedTimeframe] = useState('24H');
  const [chartUrl, setChartUrl] = useState(''); 
  const [metalData, setMetalData] = useState<BaseDataI | null>(null);  
  const [loading, setLoading] = useState(false);

  useGoogleAnaliticsHandler('Precious_Metals_Charts');
  const {widgetWrapperRef} = useSwitchThemeModeInWidget(config.colorTheme); 
  const isSmall = config.width < 400;

  const getData = async (symbol: string) => {
    try {
      setLoading(true); 
      const response = await ApiService.getCertainPM(symbol); 
      if(response) {
        const data = response.PreciousMetals.PM; 
        setMetalData(data);
      }
    } catch (error) {
      console.log('error', error); 
    } finally {
      setLoading(false); 
    } 
  }

  useEffect(() => {
    const url = `https://proxy.kitco.com/kcast/live/${shortSymbolFormatted[selectedMetal]}/438_235/${selectedMetal}_${formatTimeframe[selectedTimeframe]}_USD_oz.gif`; 
    setChartUrl(url);
    getData(selectedMetal);
  }, [selectedMetal, selectedTimeframe]);

  return (
    <div ref={widgetWrapperRef} style={{width: !config?.useContainerSize ? `${config.width}px` : '100%'}}>
      <Card className={`w-full border rounded-lg shadow-lg p-1 text-xs bg-white text-gray-dark dark:bg-gray-dark dark:text-white ${config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`}>
        <CardContent className={clsx(isSmall ? 'p-2' : 'p-4')}>
          <Tabs value={selectedMetal} onValueChange={setSelectedMetal}>
            <TabsList className={clsx("grid grid-cols-5 mb-4 dark:bg-gray", isSmall ? 'gap-1' : 'gap-2')}>
              {metals.map(metal => (
                <TabsTrigger key={metal.symbol} value={metal.symbol} className={isSmall ? 'text-[10px]' : "text-xs"}>
                  {metal.name}
                </TabsTrigger>
              ))}
            </TabsList>
            {metals.map(metal => (
              <TabsContent key={metal.symbol} value={metal.symbol}>
                <div className="mt-4">
                  <div className="flex items-center justify-between mb-4">
                    <h2 className="text-xl font-bold">{metal.name} Price</h2>
                    <div className="text-right">
                      <p className="text-xl font-bold">
                       USD {metalData?.Bid ? humanizePrice(metalData?.Bid, 2) : null}
                      </p>
                      {metalData && <p className={clsx('text-sm',
                        generatePriceChangeColorsWithTheme(metalData?.Change) 
                      )}>
                        {metalData?.Change.toFixed(2)} ({metalData?.ChangePercentage.toFixed(2)}%)
                      </p>}
                    </div>
                  </div>
                
                  <div className='w-full flex justify-center'>
                    <img
                      src={chartUrl}
                      alt={`chart-${selectedMetal}`}
                      className="w-full h-full object-fit dark:bg-white"/> 
                  </div>
  

                  <div className="mt-4 flex justify-between">
                    {timeframes.map(timeframe => (
                      <button
                        key={timeframe}
                        onClick={() => setSelectedTimeframe(timeframe)}
                        className={`py-1 ${isSmall ? 'text-[11px] w-[30px]' : 'text-xs w-[38px]'} rounded ${selectedTimeframe === timeframe ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray'}`}
                      >
                        {timeframe}
                      </button>
                    ))}
                  </div>
                </div>
              </TabsContent>
            ))}
          </Tabs>
        </CardContent>
        <CardFooter className="pt-2 pb-4 flex justify-center">
          <LinkToKitco isDarkMode={config.colorTheme === 'dark'} widgetName='Precious_Metals_Charts'/>
        </CardFooter>
      </Card>
    </div>
  );
};

export default PreciousMetalsWidget;
