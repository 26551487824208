import routes from "common/routes";
import { useGoogleAnaliticsHandlerBase } from "hooks/useGoogleAnaliticsBase";
import { Suspense } from "react";
import { useRoutes } from "react-router-dom";

function App() {
  const content = useRoutes(routes); 
  console.log('load-------');
  useGoogleAnaliticsHandlerBase();
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        {content} 
      </Suspense>
    </>
  );
}

export default App;
