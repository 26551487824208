import React from 'react';
import settings_icon from '../assets/tutorial/Kitco Branded Metal Performance-Settings.png'; 
import arrow_icon from '../assets/tutorial/arrow-to-code.svg'; 
import embed_code_icon from '../assets/tutorial/Kitco Branded Metal Performance-Embed.png'; 
import { useNavigate } from 'react-router-dom';
import { LucideCircleX, MoveLeftIcon, MoveRightIcon } from 'lucide-react';
import TutorialTextItem from 'components/TutorialTextItem';

const tutorial = [
  {id: 1, text: 'Select your precious metal'}, 
  {id: 2, text: 'Select the weight unit'}, 
  {id: 3, text: 'Select your currency'},
  {id: 4, text: 'Select your widget width'},
  {id: 5, text: 'Select light or dark mode'},
  {id: 6, text: 'Copy your widget settings'},
  {id: 7, text: 'Apply your widget embed code'},
]; 


function TutorialBrandedPage() {
  const navigate = useNavigate(); 
  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
       <div className='w-full flex justify-end pl-4 pt-2'>
{/*         <img
          src={logoUrl.light} 
           alt="logo"
          className='h-[30px] cursor-pointer'
          onClick={() => navigate('/')}
        /> */}
         <div className='pr-2'><LucideCircleX className='cursor-pointer' width={30} height={30} onClick={() => navigate('/widgets')}/></div>
      </div>
      <div className='text-[24px] lg:text-[30px] font-bold pb-4 flex items-center px-2 lg:px-0 space-x-2 lg:space-x-4 pt-[40px] lg:pt-0'><MoveLeftIcon onClick={() => navigate('/widgets/tutorial/calculator')} width={30} height={30} className='cursor-pointer'/> <span>Kitco Branded Metal Performance</span></div>
      <div className='w-full lg:w-2/3 flex flex-col items-center px-[20px] lg:px-0'>    
          <div className='flex flex-col lg:flex-row w-full items-center pb-[60px] space-y-4 lg:space-y-0 lg:space-x-4'>
              <div className='w-full lg:w-1/2 flex justify-end'>
                <img src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/Historical-Performance.png'} alt='logo' className='max-h-[500px]'/>
              </div>
              <div className='w-full lg:w-1/2 space-y-3 pb-2'>
               {tutorial.slice(0, 3).map(item => <TutorialTextItem key={item.id} index={item.id} text={item.text}/>)}
              </div>
          </div>
          <div className='w-full'>
            <div className='text-[25px] font-medium pb-[34px]'>Settings</div>
            <div className='flex w-full items-center flex-col lg:flex-row'>
              <div className='w-full lg:w-[60%]'>
                <img src={settings_icon} alt='logo' className='w-full lg:w-auto'/>
              </div>
              <div className='w-full lg:w-[40%] space-y-3 pb-2 lg:pl-[50px] pt-[40px] lg:pt-0'>
               {tutorial.slice(3).map(item => <TutorialTextItem key={item.id} index={item.id} text={item.text}/>)}
              </div>
          </div>
          </div>
          <div className='pt-[60px] w-full'>
            <div className='text-[25px] font-medium pb-[34px] text-left'>Embed code</div>

            <div className='relative pt-[90px] lg:pt-[65px]'>
            <div className='flex z-10 absolute top-0 left-[20px] flex-col-reverse lg:flex-row'>
               <img src={arrow_icon} alt='icon' className='w-[100px] lg:w-auto'/> 
               <div className='text-[20px]'>Here is your embed code based on your customized settings.</div>
            </div>
              <img src={embed_code_icon} alt='icon' className='lg:h-[600px]'/>
            </div>
          </div>
      </div>
    </div>
  );
}

export default TutorialBrandedPage;