import { Path } from "common/constants";

export const listMenu: MenuOption[] = [
    {
        title: 'Widgets', 
        options: [
            {
                title: 'Kitco Global Index',
                short_title: 'Kitco Global Index (KGX)', 
                subOptions: [
                    {
                        title: 'KGX Advanced',
                        path: Path.KGX,
                    },
                    {
                        title:'KGX Compact',
                        path: Path.METAL_BID          
                    },
                    {
                        title: 'KGX Table',
                        path: Path.COMODITY_KGX
                    }
                ]
            }, 
            {
                title: 'Precious Metals', 
                short_title: 'Precious Metals', 
                subOptions: [
                    {
                        title: 'Gold Small',
                        path: Path.SMALL_GOLD_PRICE
                    },
                    /* {
                        title: 'Gold Bid',
                        path: Path.GOLD_BID
                    }, */
                    {
                        title: 'Spot Price',
                        path: Path.SPOT_PRICE
                    },
                    {
                        title: 'London Fix',
                        path: Path.LONDON_FIX
                    },
                    {
                        title: 'Cross Rates',
                        path: Path.CROSS_RATES
                    },
                ], 
            }, 
/*             {
                title: 'Tables',
                short_title: 'Tables', 
                subOptions: [
                    {
                        title: 'KGX Table',
                        path: Path.COMODITY
                    }, 
                    {
                        title: 'Cross Rates',
                        path: Path.CROSS_RATES
                    },
                    {
                        title: 'Metals Price',
                        path: Path.METAL_PRICE_PERFORMANCE_TABLE
                    }
                ], 
            }, */
            {
                title: 'Charts',
                short_title: 'Charts', 
                subOptions: [
                    {
                        title: '3 Days Charts',
                        path: Path.GOLD_CHART
                    },
                    {
                        title: 'New York Charts',
                        path: Path.NY_CHART
                    },
                    {
                        title: 'Precious Metals',
                        path: Path.PRECIOUS_METALS
                    }, 
                    {
                        title: 'Base Metals',
                        path: Path.BASE_METALS
                    }
                ], 
            },
            {
                title: 'Historical Data',
                short_title: 'Historical Data', 
                subOptions: [
                    {
                        title: 'Precious Metals Performance',
                        path: Path.BRANDED_METAL_PERFORMANCE
                    },
                   {
                        title: 'Yearly Performance',
                        path: Path.METAL_PRICE_PERFORMANCE_TABLE
                    }
                ], 
            },
            {
                title: 'News',
                short_title: 'News', 
                subOptions: [
                    {
                        title: 'News',
                        path: Path.NEWS
                    }
                ], 
            },
            {
                title: 'Calculator',
                short_title: 'Calculator', 
                subOptions: [
                    {
                        title: 'Price Calculator',
                        path: Path.PRICE_CALCULATOR
                    }
                ], 
            },
            {
                title: 'Tickers',
                short_title: 'Tickers', 
                subOptions: [
                    {
                        title: 'Precious Metals Ticker',
                        path: Path.FINANCIAL_TICKER
                    }
                ], 
            }
        ]
    },
    {
        title: 'Tutorial',
        options: [], 
        path: Path.TUTORIAL,  
    },
    {
        title: 'Contact us',
        options: [],
        path: Path.CONTACT_US, 
    }, 
    {
        title: 'About Kitco',
        options: [], 
        path: 'https://corp.kitco.com/',
        isExternal: true
    }
]