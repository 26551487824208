import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface HookParams {
  widthDefault: number;
}

export function useSettingsWidgetHandler({ widthDefault }: HookParams) {
  const [widthValue, setWidthValue] = useState(widthDefault);
  const [theme, setTheme] = useState<'light' | 'dark'>('light');
  const [isContainerChecked, setIsContainerChecked] = useState(false);
  const [isTransparentChecked, setIsTransparentChecked] = useState(false);
  const [config, setConfig] = useState<WidgetConfig>({
    width: widthDefault,
    isTransparent: false,
    colorTheme: 'light',
    useContainerSize: false
  });

  const handleThemeChange = (e: any) => {
    console.log('theme', e.target.value);
    setTheme(e.target.value);
    if(e.target.value === 'dark') {
      setIsTransparentChecked(false);
    }
  };

  const handleChange = (e: any) => {
    let inputValue = e.target.value;
    if (inputValue === '' || /^\d*$/.test(inputValue)) {
      setWidthValue(inputValue);
    }
  };

  const handleBlur = (limit:number) => {
    // Enforce minimum value of 300
    if (Number(widthValue) < limit) {
      setWidthValue(limit);
    }
  };

  const handleContainerChecked = (e: any) => {
/*     if (e.target.checked) {
      setWidthValue(1200);
    } else {
      setWidthValue(widthDefault); 
    } */
    setIsContainerChecked(e.target.checked);
  };

  const handleTrasparentChecked = (e: any) => {
    setIsTransparentChecked(e.target.checked);
  };

  const handleApply = () => {
    setConfig({
      width: widthValue,
      isTransparent: isTransparentChecked,
      colorTheme: theme,
      useContainerSize: isContainerChecked
    }); 
  }

  const handleResetSettings = () => {
    setWidthValue(widthDefault); 
    setTheme('light'); 
    setIsContainerChecked(false);
    setIsTransparentChecked(false); 
    setConfig({
      width: widthDefault,
      isTransparent: false,
      colorTheme: 'light',
      useContainerSize: false
    }); 
  }

/*   useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);  */

  return {
    widthValue,
    theme,
    isContainerChecked,
    isTransparentChecked,
    handleThemeChange,
    handleChange,
    handleContainerChecked,
    handleTrasparentChecked,
    handleResetSettings, 
    handleApply,
    handleBlur, 
    config
  };
}
