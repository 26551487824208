import React, { useState, useEffect, useRef } from 'react';
import { CirclePause, CirclePlay } from 'lucide-react';
import ApiService from 'api/apiService';
import { shortSymbol, unitsLongName, unitsNames } from '../common/constants';
import clsx from 'clsx';
import LinkToKitco from '../components/Link/LinkToKitco';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import { DateNYTime } from '../components/Date/DateNYTime';

interface CalculatorWidgetProps {
  config: WidgetConfig; 
}

const FinalKitcoPriceCalculator = ({config}: CalculatorWidgetProps) => {
  const [quantity, setQuantity] = useState(1);
  const [instrument, setInstrument] = useState('Gold');
  const [unit, setUnit] = useState('oz');
  const [currency, setCurrency] = useState('USD');
  const [price, setPrice] = useState<any>(null);
  const [displayPrice, setDisplayPrice] = useState<any>('');
  const [listMetals, setListMetals] = useState<BaseDataI[]>([]); 
  const [change, setChange] = useState<any>(null);
  const [changePercent, setChangePercent] = useState<any>(0);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isLive, setIsLive] = useState(true);
  console.log('config--------', config);  

  const isSm = config.width < 400;

  const {widgetWrapperRef} = useSwitchThemeModeInWidget(config.colorTheme);

  const instruments = ['Gold', 'Silver', 'Platinum', 'Palladium'];
  const units = ['oz', 'g', 'kg'];
  const currencies = ['USD', 'CAD', 'INR', 'AUD', 'EUR', 'GBP', 'JPY', 'CHF', 'ZAR', 'CNY', 'BRL', 'MXN', 'HKD', 'RUB'];
  const intervalRef = useRef<any>(null);
  const basePrice = useRef<any>(2504.11);

  const formatPriceHandler = (value: string) => {
    if (!value) return "";
    const numericValue = value.replace(/[^0-9.]/g, "");
    const [integer, decimal] = numericValue.split(".");
    const formattedInteger = parseInt(integer || "0", 10).toLocaleString("en-US");
    return decimal !== undefined ? `${formattedInteger}.${decimal.length > 1 ? decimal : decimal + '0' }` : `${formattedInteger}.00`;
  };

  const updateStateHandler = (data: BaseDataI[], instrument: string) => {
    const currentMetal = data.find(item => shortSymbol[item.Symbol] === instrument.toLowerCase()); 
    const value = currentMetal?.Bid ? formatPriceHandler(`${currentMetal?.Bid}`) : 0; 
    setPrice(currentMetal?.Bid ?? 0);
    setDisplayPrice(value ?? '');
    setChange(currentMetal?.Change);
    setChangePercent(currentMetal?.ChangePercentage);
    basePrice.current = formatPriceHandler(`${currentMetal?.Bid}`);
  }

  const getData = async (currency: string, unit: string) => {
    try {
      const response = await ApiService.getPMWithParams(currency, unitsNames[unit]);
      setListMetals(response.PreciousMetals.PM); 
      updateStateHandler(response.PreciousMetals.PM, instrument); 
      setCurrentTime(new Date());
    } catch (error) {
      console.log('error', error); 
    }
  }

  useEffect(() => {
    if (isLive) {
      getData(currency, unit);
      const interval = setInterval(() => {
        getData(currency, unit);
      }, 20000); // 20 seconds

      return () => clearInterval(interval);
    }
  }, [currency, unit, isLive]);

  useEffect(() => {
    if(listMetals.length) {
      updateStateHandler(listMetals, instrument); 
    }
  }, [instrument, listMetals]); 

  const handleQuantityChange = (e: any) => {
    const value = e.target.value;
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setQuantity(value);
    }
  };

  const handlePriceChange = (e: any) => {
    const value = formatPriceHandler(e.target.value);
    setDisplayPrice(value);
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
        setPrice(numericValue);
        setIsLive(false);
        const newChange = numericValue - basePrice.current;
        setChange(newChange);
        setChangePercent((newChange / basePrice.current) * 100);
      }
    }
  };

  const handlePriceBlur = () => {
    setDisplayPrice(price.toFixed(2));
  };

  const formatPrice = (value: any) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const calculatePrice = () => {
    let basePrice = price * quantity;
    // if (unit === 'g') basePrice /= 31.1034768;
    // if (unit === 'kg') basePrice *= 32.1507466;
    return formatPrice(basePrice);
  };

  const toggleLivePrice = () => {
    setIsLive(!isLive);
    if (!isLive) {
      // simulatePriceChange();
      getData(currency, unit); 
    }
  };

  return (
    <div ref={widgetWrapperRef} className='w-full flex justify-center'>
      <div className={`font-sans border border-gray-300 shadow-lg rounded-lg overflow-hidden bg-white text-gray-dark dark:bg-gray-dark dark:text-white ${config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} style={{width: !config?.useContainerSize ? `${config.width}px` : '100%'}}>
        <div className="bg-gray-light_100 dark:bg-gray-light_700 p-4 border-b border-gray-300 flex justify-between items-center">
          <div className={clsx(!isSm ? 'w-1/4' : 'w-1/5')}>
            <img src={'https://storage.googleapis.com/kitco-widgets-storage/assets-widgets/kitco-logo.svg'} alt="logo" className={`mr-2 ${isSm ? 'h-[15px]' : 'h-[16px]'}`} />
          </div>

          <div className='w-2/4 flex grow justify-center'>
            <h2 className={clsx("font-bold text-center flex-grow text-gray-dark dark:text-gray-light_100", {
              'text-[18px]': !isSm, 
              'text-[14px]': isSm
            })}>{instrument} Calculator</h2>
          </div>

          <div className={clsx('flex justify-end', isSm ? 'w-1/5' : 'w-1/4')}>
            <div onClick={toggleLivePrice} className='cursor-pointer'  title={isLive ? "Press to pause live prices" : "Press to resume live prices"}>
             {isLive ? <CirclePlay size={isSm ? 20 : 26} className="text-green-dark dark:text-green-light"/> : <CirclePause size={isSm ? 20 :  26} className="text-red-dark dark:text-red-light"/>}
            </div>

          </div>

        </div>
        <div className={`${isSm ? 'p-2 text-[12px]' : 'p-4 text-[14px]'}`}>
          <div className="flex items-center mb-4">
            <input
              type="text"
              value={quantity}
              onChange={handleQuantityChange}
              className={clsx("mr-2 rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white h-[40px]", {
                "w-[60px] p-2": !isSm,
                "w-[40px] p-1": isSm
              })}
            />
            <div className={clsx("relative inline-block mr-2", isSm ? 'w-[70px]' : 'w-[80px]' )}>
              <select
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                className={`w-full ${isSm ? 'p-1' : 'p-2'} rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white h-[40px]`}
              >
                {units.map(u => <option key={u} value={u}>{unitsLongName[u]}</option>)}
              </select>

            </div>
            <div className="relative inline-block flex-grow mr-2">
              <select
                value={instrument}
                onChange={(e) => setInstrument(e.target.value)}
                className={`w-full ${isSm ? 'p-1' : 'p-2'} rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white h-[40px]`}
              >
                {instruments.map(i => <option key={i} value={i}>{i}</option>)}
              </select>
            </div>
            <div className={clsx("relative inline-block", isSm ? 'w-[60px]' : 'w-[80px]')}>
              <select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                className={`w-full ${isSm ? 'p-1' : 'p-2'} rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white h-[40px]`}
              >
                {currencies.map(c => <option key={c} value={c}>{c}</option>)}
              </select>
            </div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <span className="font-semibold">{`Price per ${unitsLongName[unit]}:`}</span>
            <input
              type="text"
              value={displayPrice}
              onChange={handlePriceChange}
              onBlur={handlePriceBlur}
              className={`font-bold text-right ${isSm ? 'w-24 text-[16px]' : 'w-32 text-xl'} p-1 text-[14px] rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white h-[40px]`}
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <span className="font-semibold">Total:</span>
            <span className={`${isSm ? 'text-[16px]' : "text-xl"} font-bold`}>{calculatePrice()}</span>
          </div>
          <div className="flex justify-between items-center mb-4">
            <span className="font-semibold">Change:</span>
            <div>
              <span className={clsx(`font-bold`, {
                 'text-green-dark dark:text-green-light': change > 0,
                 'text-red-dark dark:text-red-light': change < 0,
                 'text-gray dark:text-gray-subtext_light': change === 0,
              })}>
                {formatPrice(change)}
              </span>
              <span className={clsx(`ml-2`, {
                 'text-green-dark dark:text-green-light': change > 0,
                 'text-red-dark dark:text-red-light': change < 0,
                 'text-gray dark:text-gray-subtext_light': change === 0,
              })}>
                ({changePercent >= 0 ? '+' : ''}{changePercent.toFixed(2)}%)
              </span>
            </div>
          </div>
        </div>
        <div className={`bg-gray-light_100 dark:bg-gray-light_700 p-2 flex justify-between items-center ${isSm ? 'text-[12px] flex-col' : 'text-sm'} text-gray-600 border-t border-gray-300`}>
          <DateNYTime fontSize='12px'/>
          <LinkToKitco isDarkMode={config.colorTheme === 'dark'}/>
        </div>
      </div>
    </div>
  );
};

export default FinalKitcoPriceCalculator;
