import ReactGA from "react-ga4";
import { useEffect } from "react";

export function useGoogleAnaliticsHandlerBase() {
    useEffect(() => {
        const hostDomain = window.location.origin;
        const hostPath = window.location.pathname;
        const stage_origin = 'https://goldlive-8ca30.uc.r.appspot.com'; 
        const prod_origin_prev = 'https://kitco-widgets.uc.r.appspot.com';
        const prod_origin = 'https://widgets.kitco.com/'; 

        if(hostDomain !== stage_origin && hostDomain !== prod_origin && hostDomain !== prod_origin_prev) return;

        console.log('Initialize kitco GA');
        ReactGA.initialize("G-X01FQE4SYV");
/*         const hostPath = window.location.pathname;

        ReactGA.send({
            hitType: "pageview",
            page: hostPath,
            title: `Kitco-Widgets`,
        }); */

    }, []);
}