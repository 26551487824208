import ReactGA from "react-ga4";
import { useEffect } from "react";

export function useGoogleAnaliticsHandler(widgetName: string) {
    useEffect(() => {
        const hostDomain = window.location.origin;
        const hostPath = window.location.pathname;
        const stage_origin = 'https://goldlive-8ca30.uc.r.appspot.com'; 
        const prod_origin_prev = 'https://kitco-widgets.uc.r.appspot.com';
        const prod_origin = 'https://widgets.kitco.com/';

        if(hostDomain === stage_origin || hostDomain === prod_origin || hostDomain === prod_origin_prev) return; 

        console.log('Initialize widget GA'); 
        ReactGA.initialize("G-X01FQE4SYV");

        if (widgetName) {

            ReactGA.set({
                dimension1: widgetName,
                dimension2: hostDomain,
              });

            ReactGA.gtag('event', 'page_view_widget', {
                page_title: widgetName,
                page_location: hostPath,
                page_path: hostPath,
                dimension1: widgetName,
                dimension2: hostDomain,
            });

/*             ReactGA.send({
                hitType: "pageview",
                page: hostPath,
                title: `Widget: ${widgetName}`,
                dimension1: widgetName,      
                dimension2: hostDomain,   
            }); */

/*             ReactGA.event({
                category: "Widget",
                action: "Widget Loaded",
                label: `${widgetName} | ${hostDomain}`,
                value: 1,                     
                nonInteraction: true,       
           }); */
        }

    }, [widgetName]);
}