import React, { useState } from 'react';
import { ChevronDown, ArrowUp, ArrowDown } from 'lucide-react';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';
import LinkToKitco from '../components/Link/LinkToKitco';
import { DateNYTime } from '../components/Date/DateNYTime';
import clsx from 'clsx';
import { generatePriceChangeColorsWithTheme } from '../components/utils';
import { unitsLongName } from 'common/constants';

interface KitcoBrandedMetalPerformanceProps {
  config: WidgetConfig; 
}

const KitcoBrandedMetalPerformance: React.FC<KitcoBrandedMetalPerformanceProps>  = ({config}) => {
  const [selectedMetal, setSelectedMetal] = useState('GOLD');
  const [selectedUnit, setSelectedUnit] = useState('oz');
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [showChart, setShowChart] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('');

  const {widgetWrapperRef} = useSwitchThemeModeInWidget(config.colorTheme);

  const metals = ['GOLD', 'SILVER', 'PLATINUM', 'PALLADIUM'];
  const units = ['oz', 'kg', 'g', 'tola'];
  // const currencies = ['USD', 'CAD', 'EUR', 'GBP', 'JPY', 'CHF', 'AUD'];

  const currencies = ['USD', 'CAD', 'EUR', 'GBP', 'JPY', 'CHF', 'AUD', 'CNY', 'INR', 'MXN', 'BRL', 'ZAR', 'RUB'];

  const metalData: any = {
    GOLD: {
      bid: 2403.90,
      change: -3.73,
      changePercent: -0.15,
      timePeriodsAndChanges: [
        { period: '1 Day', change: -3.73, changePercent: -0.15 },
        { period: '3 Days', change: 15.20, changePercent: 0.63 },
        { period: '7 Days', change: 32.10, changePercent: 1.30 },
        { period: '30 Days', change: 103.18, changePercent: 4.30 },
        { period: '6 Months', change: 487.88, changePercent: 24.20 },
        { period: '1 Year', change: 614.60, changePercent: 32.53 },
        { period: '5 Years', change: 1007.82, changePercent: 67.35 },
        { period: '10 Years', change: 1208.41, changePercent: 93.26 },
        { period: '15 Years', change: 1559.78, changePercent: 165.17 }
      ]
    },
    SILVER: {
      bid: 27.78,
      change: -0.12,
      changePercent: -0.43,
      timePeriodsAndChanges: [
        { period: '1 Day', change: -0.12, changePercent: -0.43 },
        { period: '3 Days', change: 0.45, changePercent: 1.65 },
        { period: '7 Days', change: 0.88, changePercent: 3.27 },
        { period: '30 Days', change: 2.15, changePercent: 8.38 },
        { period: '6 Months', change: 5.30, changePercent: 23.57 },
        { period: '1 Year', change: 7.45, changePercent: 36.64 },
        { period: '5 Years', change: 12.80, changePercent: 85.33 },
        { period: '10 Years', change: 15.20, changePercent: 120.63 },
        { period: '15 Years', change: 18.75, changePercent: 207.77 }
      ]
    },
    PLATINUM: {
      bid: 950.00,
      change: 12.00,
      changePercent: 1.28,
      timePeriodsAndChanges: [
        { period: '1 Day', change: 12.00, changePercent: 1.28 },
        { period: '3 Days', change: 25.50, changePercent: 2.76 },
        { period: '7 Days', change: 42.30, changePercent: 4.66 },
        { period: '30 Days', change: 78.60, changePercent: 9.02 },
        { period: '6 Months', change: 185.20, changePercent: 24.21 },
        { period: '1 Year', change: 240.50, changePercent: 33.89 },
        { period: '5 Years', change: 375.80, changePercent: 65.38 },
        { period: '10 Years', change: 450.30, changePercent: 90.06 },
        { period: '15 Years', change: 580.70, changePercent: 157.48 }
      ]
    },
    PALLADIUM: {
      bid: 891.00,
      change: 5.00,
      changePercent: 0.56,
      timePeriodsAndChanges: [
        { period: '1 Day', change: 5.00, changePercent: 0.56 },
        { period: '3 Days', change: 18.20, changePercent: 2.08 },
        { period: '7 Days', change: 35.60, changePercent: 4.16 },
        { period: '30 Days', change: 72.30, changePercent: 8.83 },
        { period: '6 Months', change: 165.80, changePercent: 22.85 },
        { period: '1 Year', change: 220.40, changePercent: 32.85 },
        { period: '5 Years', change: 345.60, changePercent: 63.40 },
        { period: '10 Years', change: 420.20, changePercent: 89.27 },
        { period: '15 Years', change: 550.50, changePercent: 161.44 }
      ]
    }
  };

  const handlePeriodClick = (period: any) => {
    setSelectedPeriod(period);
    setShowChart(true);
  };

  const formatValue = (value: number) => {
    const unitConversion: any = { oz: 1, kg: 0.0311034768, g: 31.1034768, tola: 2.66666 };
    const currencyConversion: any = { 
      USD: 1, CAD: 1.25, EUR: 0.85, GBP: 0.75, JPY: 110, CHF: 0.92,
      AUD: 1.35, NZD: 1.45, CNY: 6.5, INR: 75, MXN: 20, BRL: 5.3,
      ZAR: 15, RUB: 75 };


    
    const convertedValue = value * unitConversion[selectedUnit] * currencyConversion[selectedCurrency];
    return convertedValue.toFixed(2);
  };

  return (
    <div ref={widgetWrapperRef} className='w-full flex justify-center'>
      <div className={`font-sans w-[400px] border border-gray-300 bg-white text-gray-dark dark:bg-gray-dark dark:text-white overflow-x-hidden p-2 ${config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`}  style={{width: !config?.useContainerSize ? `${config.width}px` : '100%'}}>
        <div className="bg-black text-white p-3">
          <h2 className="text-lg font-bold">Kitco.com Precious Metals Performance</h2>
          <p className="text-sm">
            <DateNYTime fontSize='12px' isLightMode/>
          </p>
        </div>
        <div className="p-3">
          <div className="flex justify-between mb-3 space-x-2">
            <div className="relative inline-block w-1/3">
              <select
                value={selectedMetal}
                onChange={(e) => setSelectedMetal(e.target.value)}
                className="w-full mr-2 lg:mr-2 p-1 my-2 text-[14px] rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white h-[40px]"
              >
                {metals.map((metal) => (
                  <option key={metal} value={metal}>{metal}</option>
                ))}
              </select>
            </div>
            <div className="relative inline-block w-1/3">
              <select
                value={selectedUnit}
                onChange={(e) => setSelectedUnit(e.target.value)}
                className="w-full mr-2 lg:mr-2 p-1 my-2 text-[14px] rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white h-[40px]"
              >
                {units.map((unit) => (
                  <option key={unit} value={unit}>{unitsLongName[unit]}</option>
                ))}
              </select>
            </div>
            <div className="relative inline-block w-1/3">
              <select
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
                className="w-full mr-2 lg:mr-2 p-1 my-2 text-[14px] rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white h-[40px]"
              >
                {currencies.map((currency) => (
                  <option key={currency} value={currency}>{currency}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-3 p-2 bg-gray-light_100 dark:bg-gray rounded dark:text-white">
            <div className="flex justify-between items-center">
              <span className="font-bold">Current Bid:</span>
              <span className="font-bold">{formatValue(metalData[selectedMetal].bid)} {selectedCurrency}/{selectedUnit}</span>
            </div>
            <div className="flex justify-between items-center">
              <span>Change:</span>
              <span className={clsx('', generatePriceChangeColorsWithTheme(metalData[selectedMetal].change))}>
                {metalData[selectedMetal].change >= 0 ? <ArrowUp size={16} className="inline" /> : <ArrowDown size={16} className="inline" />}
                {formatValue(Math.abs(metalData[selectedMetal].change))} ({metalData[selectedMetal].changePercent.toFixed(2)}%)
              </span>
            </div>
          </div>
          <table className="w-full text-sm dark:text-white">
            <thead>
              <tr className="border-b">
                <th className="text-left py-1">Time</th>
                <th className="text-right py-1">Change</th>
                <th className="text-right py-1">Change%</th>
              </tr>
            </thead>
            <tbody>
              {metalData[selectedMetal].timePeriodsAndChanges.map(({ period, change, changePercent }: any) => (
                <tr key={period} className="border-b last:border-b-0 hover:bg-gray-100 cursor-pointer" onClick={() => handlePeriodClick(period)}>
                  <td className="py-1">{period}</td>
                  <td className={clsx(`text-right py-1`, generatePriceChangeColorsWithTheme(change))}>
                    {change >= 0 ? '+' : ''}{formatValue(change)}
                  </td>
                  <td className={clsx('text-right py-1', generatePriceChangeColorsWithTheme(changePercent))}>
                    {changePercent >= 0 ? '+' : ''}{changePercent.toFixed(2)}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-center p-2 bg-gray-light_100 dark:bg-gray">
        
{/*           <Logo className="mr-2 h-[20px]" />
          <a href="https://www.kitco.com" target="_blank" rel="noopener noreferrer" className="text-sm text-blue-600 hover:text-blue-800">
            Powered by Kitco.com
          </a> */}
          <LinkToKitco isDarkMode={config.colorTheme === 'dark'}/>
        </div>
        {showChart && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 dark:text-white">
            <div className="bg-white p-4 rounded-lg max-w-3xl max-h-[80vh] overflow-auto">
              <h3 className="text-lg font-bold mb-2">Chart for {selectedMetal} - {selectedPeriod}</h3>
              <img src="/api/placeholder/800/600" alt="Placeholder Chart" className="w-full h-auto" />
              <button 
                onClick={() => setShowChart(false)}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Close Chart
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KitcoBrandedMetalPerformance;