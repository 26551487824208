import { codeList } from "./codeList";

interface EmbedCodeProps {
  width: string;
  isTransparent: boolean;
  colorTheme: 'light' | 'dark';
  embedCodeConfig: EmbedCodeConfig; 
}

function EmbedCode({
  width,
  isTransparent,
  colorTheme,
  embedCodeConfig, 
}: EmbedCodeProps) {
  return (
    <div className="w-full">
      <div className='text-[20px] font-semibold'>Embed code</div>
      <div className='w-full bg-slate-100 rounded-lg shadow-md border border-gray-200 mt-4 px-1'>
        <div className='whitespace-pre-wrap w-full text-[12px] lg:text-[14px] text-gray-600 font-medium break-words'>
         {embedCodeConfig && codeList.embed_code_copy(embedCodeConfig, { width, isTransparent, colorTheme })}
        </div>
      </div>
    </div>
  );
}

export default EmbedCode;
