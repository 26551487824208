import EmbedCode from 'components/EmbedCode/EmbedCode';
import ButtonsSection from 'components/SettingsWidget/ButtonsSection';
import SettingsWidget from 'components/SettingsWidget/SettingsWidget';
import { useSettingsWidgetHandler } from 'hooks/useSettingsWidgetHandler';
import React from 'react';
import ThreeDayChartWidget from 'widgets/ThreeDayChartWidget';
import { WidgetsConfigList } from 'common/widgetsConfig';
import { getFormattedWidth } from 'components/utils';
import { useWindowSize } from 'usehooks-ts';

function ThreeDaysChartsPage() {
    const { width } = useWindowSize();
    const {
      isContainerChecked,
      isTransparentChecked,
      widthValue,
      theme,
      handleChange,
      handleContainerChecked,
      handleThemeChange,
      handleTrasparentChecked,
      handleResetSettings,
      handleApply,
      handleBlur, 
      config
    } = useSettingsWidgetHandler({ widthDefault: width < 500 ? 300 : 400 });

  const embedCodeConfig = WidgetsConfigList.threeDaysCharts;
  return (
    <div className='pt-[20px] pb-[40px] w-full flex flex-col items-center'>
      <div className='text-[30px] mulish-600 text-silver pb-4'>Three Days Charts</div>
      <div className='w-full lg:w-1/2 flex flex-col items-center'>
    
      <ThreeDayChartWidget config={config}/>


      <SettingsWidget
          widthValue={widthValue}
          theme={theme}
          handleBlur={() => handleBlur(300)}
          isTransparentChecked={isTransparentChecked}
          isContainerChecked={isContainerChecked}
          handleChange={handleChange}
          handleContainerChecked={handleContainerChecked}
          handleThemeChange={handleThemeChange}
          defaultWidth='300'
          handleTrasparentChecked={handleTrasparentChecked}
        /> 

       <ButtonsSection
          isTransparentChecked={isTransparentChecked}
          widthValue={widthValue}
          theme={theme}
          handleApply={handleApply}
          embedCodeConfig={embedCodeConfig}
          handleResetSettings={handleResetSettings}
        />

        <EmbedCode
          width={getFormattedWidth(config.useContainerSize, widthValue)}
          isTransparent={isTransparentChecked}
          colorTheme={theme}
          embedCodeConfig={embedCodeConfig}
        />
      </div>
    </div>
  );
}

export default ThreeDaysChartsPage;