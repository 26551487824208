import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardFooter } from '../@/components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../@/components/ui/tabs';
import LinkToKitco from '../components/Link/LinkToKitco';
import { shortSymbolFormatted } from '../common/constants';
import ApiService from 'api/apiService';
import clsx from 'clsx';
import { generatePriceChangeColorsWithTheme, humanizePrice } from '../components/utils';
import useSwitchThemeModeInWidget from '../hooks/useSwitchThemeModeInWidget';

interface PropsI {
  config: WidgetConfig;
}

const metals = [
    { name: 'Copper', symbol: 'CU', color: '#B87333' },
    { name: 'Nickel', symbol: 'NI', color: '#A6A6A6' },
    { name: 'Aluminum', symbol: 'AL', color: '#A9A9A9' },
    { name: 'Zinc', symbol: 'ZN', color: '#7F7F7F' },
    { name: 'Lead', symbol: 'PB', color: '#6E6E6E' },
    { name: 'Uranium', symbol: 'UR', color: '#4E4E4E' }
  ];
const timeframes = ['1D', '1M', '2M', '6M', '1Y', '5Y'];
const formatTimeframe: any = {
  '1D': '24h',
  '1M': '30d',
  '2M': '60d',
  '6M': '180d',
  '1Y': '365d',
  '5Y': '1825d'
}

const BaseMetalsChartsWidget = ({config}: PropsI) => {
  const [selectedMetal, setSelectedMetal] = useState('CU');
  const [selectedTimeframe, setSelectedTimeframe] = useState('1D');
  const [chartUrl, setChartUrl] = useState(''); 
  const [metalData, setMetalData] = useState<BaseDataI | null>(null);  
  const [loading, setLoading] = useState(false);

  const {widgetWrapperRef} = useSwitchThemeModeInWidget(config.colorTheme); 
  const isSmall = config.width < 400;

  const getData = async (symbol: string) => {
    try {
      setLoading(true); 
      const response = await ApiService.getCertainBM(symbol); 
      if(response) {
        const data = response.BaseMetals.PM; 
        setMetalData(data);
      }
    } catch (error) {
      console.log('error', error); 
    } finally {
      setLoading(false); 
    } 
  }

  useEffect(() => {
    if(selectedTimeframe === '1D' && selectedMetal === 'UR') {
      setSelectedTimeframe('1M');
      const url = `https://kds2.kitco.com/kcast/live/base/438_235/${selectedMetal}_30d_usd_lb.gif`; 
      setChartUrl(url);
    } else {
      const url = `https://kds2.kitco.com/kcast/live/base/438_235/${selectedMetal}_${formatTimeframe[selectedTimeframe]}_usd_lb.gif`; 
      setChartUrl(url);
    }
    getData(selectedMetal);
  }, [selectedMetal, selectedTimeframe]);

  return (
    <div ref={widgetWrapperRef} style={{width: !config?.useContainerSize ? `${config.width}px` : '100%'}}>
      <Card className={`w-full border border-gray-300 p-1 text-xs bg-white text-gray-dark dark:bg-gray-dark dark:text-white ${config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`}>
        <CardContent className={clsx(isSmall ? 'p-2' : 'p-4')}>
          <Tabs value={selectedMetal} onValueChange={setSelectedMetal}>
            <TabsList className={clsx("grid grid-cols-6 mb-4 dark:bg-gray", isSmall ? 'gap-1' : 'gap-2')}>
              {metals.map(metal => (
                <TabsTrigger key={metal.symbol} value={metal.symbol} className={isSmall ? 'text-[10px]' : "text-xs"}>
                  {metal.name}
                </TabsTrigger>
              ))}
            </TabsList>
            {metals.map(metal => (
              <TabsContent key={metal.symbol} value={metal.symbol}>
                <div className="mt-4">
                  <div className="flex items-center justify-between mb-4">
                    <h2 className="text-xl font-bold">{metal.name} Price</h2>
                    <div className="text-right">
                      <p className="text-xl font-bold">
                       USD {metalData?.Bid ? humanizePrice(metalData?.Bid, 2) : null}
                      </p>
                      {metalData && <p className={clsx('text-sm',
                        generatePriceChangeColorsWithTheme(metalData?.Change) 
                      )}>
                        {metalData?.Change.toFixed(2)} ({metalData?.ChangePercentage.toFixed(2)}%)
                      </p>}
                    </div>
                  </div>
                
                  {(selectedTimeframe === '1D' && selectedMetal === 'UR') ? 
                    <div className={clsx("text-center text-sm w-full flex items-center justify-center", isSmall ? 'h-[147px]' : 'h-[192px]')}> 
                      Uranium chart is not available for 24 hours timeframe
                    </div>
                  : <img
                    src={chartUrl}
                    alt={`chart-${selectedMetal}`}
                    className="w-full h-full object-fit dark:bg-slate-400"/> }
                    

                  <div className="mt-4 flex justify-between">
                    {timeframes.map(timeframe => (
                      <>
                      {(timeframe === '1D' && selectedMetal === 'UR') ? null : <button
                          key={timeframe}
                          onClick={() => setSelectedTimeframe(timeframe)}
                          className={`px-2 py-1 ${isSmall ? 'text-[11px]' : 'text-xs'} rounded ${selectedTimeframe === timeframe ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray'}`}
                        >
                          {timeframe}
                        </button>}
                      </>
                    ))}
                  </div>
                </div>
              </TabsContent>
            ))}
          </Tabs>
        </CardContent>
        <CardFooter className="pt-2 pb-4 flex justify-center">
          <LinkToKitco isDarkMode={config.colorTheme === 'dark'}/>
        </CardFooter>
      </Card>
    </div>
  );
};

export default BaseMetalsChartsWidget;